import Vue from 'vue';
import Vuex from 'vuex';
import createPersist from 'vuex-localstorage';
import * as actions from './actions';
import * as getters from './getters';
import locations from './modules/locations';
import post from './modules/post';
import page from './modules/page';
import settings from './modules/settings';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const localStorage = createPersist({
  namespace: 'B4YP-2022-9.2',
  initialState: {},
  expires: 1, // 1.21e9, // Two Weeks
});

export default new Vuex.Store({
  actions,
  getters,
  modules: {
    page,
    post,
    settings,
    locations,
  },
  strict: debug,
  plugins: [localStorage],
});
