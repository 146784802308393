<template>
  <div class="input-error-container" :class="{ 'has-error': hasError }">
    <div class="input-wrapper" :class="{ 'has-input': value !== '' }">
      <input
        :id="id"
        :type="type"
        :value="value"
        @input="updateValue($event.target.value)"
      >
      <label :for="id">{{label}}</label>
    </div>
    <p v-if="requiredError" class="error-message">{{requiredErrorMessage}}</p>
    <p v-if="validationError" class="error-message">{{errorMessage}}</p>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'LabeledInput',
  props: {
    label: String,
    id: String,
    value: String,
    validate: Function,
    errorMessage: String,
    required: Boolean,
    requiredErrorMessage: {
      type: String,
      default: 'This field is required',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  mounted() {
    if (this.$parent.isComplete) this.$parent.isComplete();
  },
  destroyed() {
    this.$emit('input', '');
  },
  computed: {
    hasError() {
      return this.validationError || this.requiredError;
    },
  },
  data() {
    return {
      validationError: false,
      requiredError: false,
      isValid: !(this.required || this.validate),
      validateInput: debounce(function validateInput() {
        if (this.required && this.value === '') {
          this.requiredError = true;
          this.isValid = false;
        } else if (this.validate && !this.validate(this.value)) {
          this.validationError = true;
          this.isValid = false;
        } else {
          this.isValid = true;
        }
        if (this.$parent.isComplete) this.$parent.isComplete();
      }, 500),
    };
  },
  methods: {
    updateValue(value) {
      this.resetErrors();
      this.$emit('input', value);
      this.validateInput();
    },
    resetErrors() {
      this.requiredError = false;
      this.validationError = false;
    },
  },
};
</script>
