<template>
  <div class="input-error-container" :class="{ 'has-error': hasError }">
    <div class="input-wrapper textarea-container" :class="{ 'has-input': value !== '' }">
      <textarea
        :id="id"
        v-model="theValue"
        @input="updateValue($event.target.value)"
      ></textarea>
      <label :for="id">{{label}}</label>
    </div>
    <p v-if="requiredError" class="error-message">{{requiredErrorMessage}}</p>
    <p v-if="validationError" class="error-message">{{errorMessage}}</p>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'LabeledInput',
  props: {
    label: String,
    id: String,
    value: String,
    validate: Function,
    errorMessage: String,
    required: Boolean,
    parentIsComplete: Function,
    requiredErrorMessage: {
      type: String,
      default: 'This field is required',
    },
  },
  mounted() {
    if (this.parentIsComplete) this.parentIsComplete();
  },
  destroyed() {
    this.$emit('input', '');
  },
  computed: {
    hasError() {
      return this.validationError || this.requiredError;
    },
  },
  data() {
    return {
      validationError: false,
      requiredError: false,
      isValid: !(this.required || this.validate),
      theValue: this.value,
    };
  },
  methods: {
    updateValue(value) {
      this.resetErrors();
      this.$emit('input', value);
      this.validateInput();
    },
    validateInput: debounce(function validateInput() {
      if (this.required && this.value === '') {
        this.requiredError = true;
        this.isValid = false;
      } else if (this.validate && !this.validate(this.value)) {
        this.validationError = true;
        this.isValid = false;
      } else {
        this.isValid = true;
      }
      if (this.$parent.isComplete) this.$parent.isComplete();
    }, 500),
    resetErrors() {
      this.requiredError = false;
      this.validationError = false;
    },
  },
};
</script>
