<template>
  <header v-if="allSettingsLoaded" class="main-header">
    <div
      v-if="announcement.display"
      class="main-header__announcement"
      ref="announcement"
    >
      {{ announcement.message }}
    </div>
    <nav ref="fixedHeader">
      <div class="band--inner">
        <button
          v-if="menu.items.length >= 1"
          class="main-header--mobile-menu-trigger"
          v-on:click="toggleMobileMenu"
          :class="{ 'main-header--menu-is-open': showMobileMenu }"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <router-link to="/" class="logo" :class="{ 'no-menu': !menu.items.length }">
          <img :src="menu.logo.url" :alt="menu.logo.alt" />
        </router-link>
        <ul
          v-if="menu.items.length >= 1"
          class="mr-auto"
          :class="{ 'main-header--menu-is-open': showMobileMenu }"
        >
          <li v-for="(link, index) in menu.items" :key="index">
            <router-link :to="link.name" :title="link.title">{{
              link.label
            }}</router-link>
          </li>
        </ul>
        <button
          v-if="callToAction.display_cta"
          v-on:click="scrollToHeadStart"
          class="button"
          :class="{ 'main-header--application-button__hide': hideHeaderApplicationButton }"
        >
          {{ callToAction.label }}
        </button>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { bus } from '../main';

export default {
  name: 'MainHeader',
  computed: {
    ...mapGetters({
      menu: 'menu',
      announcement: 'announcement',
      allSettingsLoaded: 'allSettingsLoaded',
    }),
    callToAction() {
      return this.menu['call-to-action'];
    },
    sectionId() {
      return 'winners-section'; // 'head-start';
    },
  },
  data() {
    return {
      showMobileMenu: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0.25, 0.75],
      },
      waypointReached: false,
      hideHeaderApplicationButton: false,
    };
  },
  created() {
    document.addEventListener('scroll', this.handleScroll);
    bus.$on('hideHeaderApplicationButton', (value) => {
      this.hideHeaderApplicationButton = value;
    });
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll);
  },
  init() {
    this.handleScroll();
  },
  methods: {
    handleScroll() {
      const windowWidth = window.innerWidth;
      if (windowWidth < 768) return;
      const { fixedHeader } = this.$refs;
      const { announcement } = this.$refs;
      this.fixedHeaderOffset = this.fixedHeaderOffset || fixedHeader.offsetTop;
      this.fixedHeaderOffsetHeight = this.fixedHeaderOffsetHeight || fixedHeader.offsetHeight;
      const { scrollY } = window;
      if (scrollY > this.fixedHeaderOffset) {
        fixedHeader.classList.add('scrolling');
        if (announcement) announcement.style.marginTop = `-${announcement.offsetHeight}px`;
      } else if (scrollY <= this.fixedHeaderOffset) {
        fixedHeader.classList.remove('scrolling');
        if (announcement) announcement.style.marginTop = null;
      }
    },
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    async scrollToHeadStart() {
      const slug = this.callToAction.url !== '' ? new URL(this.callToAction.url).pathname : null;
      if (this.callToAction.url !== '' && slug !== '/') {
        let scrollBehavior = 'smooth';
        if (!this.$router.currentRoute.name.includes(slug)) {
          scrollBehavior = 'auto';
          await this.$router.push(slug);
        }
        if (
          this.callToAction.label.toLowerCase().includes('vote')
          && this.callToAction.url.includes('locations')
        ) {
          this.$emit('scrollToSection', 'locations-list', scrollBehavior);
        }
      } else {
        let scrollBehavior = 'smooth';
        if (this.$router.currentRoute.name !== 'home') {
          scrollBehavior = 'auto';
          await this.$router.push({ name: 'home' });
        }
        this.$emit('scrollToSection', this.sectionId, scrollBehavior);
      }
    },
  },
};
</script>
