<template>
  <h3 class="flex items-center mb-6">
    <span class="status inline-block w-8 mr-4">
      <Check v-if="completed" />
      <Minus v-else />
    </span>
    <slot></slot>
  </h3>
</template>

<script>
import Check from '../../assets/svgs/check-circle.svg';
import Minus from '../../assets/svgs/minus-circle.svg';

export default {
  name: 'FormHeadline',
  components: {
    Check,
    Minus,
  },
  props: {
    label: String,
    completed: Boolean,
  },
};
</script>
