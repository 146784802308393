<template>
  <router-link
    v-if="!!route"
    class="button"
    :class="{ bordered: bordered }"
    :to="route"
    :title="title"
  >
    <slot></slot>
  </router-link>
  <a
    v-else class="button"
    :class="{ bordered: bordered }"
    :href="href"
    :title="title"
    target="_blank"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'Button',
  props: {
    route: [Object, String],
    href: String,
    title: String,
    bordered: Boolean,
  },
  created() {
    if (!this.route && !this.href) {
      // eslint-disable-next-line no-console
      console.error('No Route or Href defined on button', this);
    }
  },
};
</script>
