<template>
  <section v-if="locationsLoaded" class="band locations-list">
      <div class="band--inner">
        <p class="standout standout--dark-teal md:mb-16 px-16 mb-10 text-center">
          Select below to filter between finalists eligible for new park
          grants and finalists eligible for grants to improve existing parks.
        </p>
        <div class="location-filters md:px-0 px-16">
          <button
            class="button md:w-1/3 md:ml-10 md:float-left relative block w-full"
            @click="toggleDisplayedFinalists('new')"
          >
            <Award
              v-if="displayedFinalistType === 'new'"
              class="award md:inline-block md:float-left absolute left-0 w-6"
            />
            New parks
            <span
              v-if="displayedFinalistType === 'new'"
              class="walking-dog"
            >
              <font-awesome-icon size="lg" :icon="['fas', 'dog']" />
            </span>
          </button>
          <button
            class="button md:w-1/3 md:mt-0 relative block w-full mt-8 ml-auto mr-10"
            @click="toggleDisplayedFinalists('existing')"
          >
            <Award
              v-if="displayedFinalistType === 'existing'"
              class="award absolute left-0 inline-block float-left w-6"
            />
            Existing parks
            <span
              v-if="displayedFinalistType === 'existing'"
              class="walking-dog"
            >
              <font-awesome-icon size="lg" :icon="['fas', 'dog']" />
            </span>
          </button>
        </div>
        <ul class="md:my-16 my-8 list-none">
          <li
            class="md:flex-row flex flex-col items-center p-4"
            v-for="location in displayedFinalists"
            :key="location.id"
          >
            <div class="md:w-4/5 md:mb-0 flex flex-row items-center w-full mb-6">
              <figure v-if="location.featuredImage" class="md:w-1/5 w-2/5">
                <img :src="location.featuredImage.source_url" :alt="location.featuredImage.alt" />
                <div
                  v-if="location.finalist"
                  class="tag tag--finalist flex flex-row items-center"
                >
                  <Award
                    class="inline-block float-left w-4 ml-auto mr-2"
                  />
                  <div class="mr-auto">{{location.year_awarded}} Finalist</div>
                </div>
              </figure>
              <div class="details md:w-4/5 w-3/5 ml-6">
                <h3 v-html="location.title.rendered"></h3>
                <p>{{ location.address }}</p>
              </div>
            </div>
            <div class="actions md:w-auto md:ml-auto w-full">
              <button
                v-if="location.enable_details_page && location.finalist"
                :to="location.slug"
                class="button secondary block w-full mb-3 ml-auto"
                @click="doOpenVotingModal(location)"
              >
                Vote!
              </button>
              <router-link
                v-if="location.enable_details_page"
                :to="location.slug"
                class="button block w-full ml-auto"
              >
                Learn More
              </router-link>
            </div>
          </li>
        </ul>
        <p class="mb-24 text-center">
          Don’t see the community you want to vote for?
          Select between “NEW PARKS” and “EXISTING PARKS”
          at the top of the page to see a list of finalist
          communities eligible for each type of grant.
        </p>
      </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { bus } from '../../main';
import Award from '../../assets/svgs/award.svg';

export default {
  name: 'FinalistLocationsList',
  props: {
    data: Object,
  },
  components: {
    Award,
  },
  computed: {
    ...mapGetters({
      finalists: 'getFinalistLocations',
      locationsLoaded: 'locationsLoaded',
      getLocationsForDisplay: 'getLocationsSubset',
      getLocationNameMatch: 'getLocationNameMatch',
      getLocationStateMatch: 'getLocationStateMatch',
      locationsMetadata: 'locationsMetadata',
      states: 'getStates',
    }),

    displayedFinalists() {
      const { displayedFinalistType } = this;
      if (!displayedFinalistType) return this.finalists;
      // eslint-disable-next-line camelcase
      return this.finalists.filter(({ park_type }) => park_type === displayedFinalistType);
    },
  },
  data() {
    return {
      displayedFinalistType: null,
    };
  },
  async mounted() {
    bus.$emit('hideHeaderApplicationButton', true);
    const metaQuery = [
      {
        key: 'year_awarded',
        value: Date('Y'),
      },
      {
        key: 'application_id',
        compare: 'EXISTS',
      },
    ];
    await this.$store.dispatch('getLocations', { per_page: 100, page: 1, meta_query: metaQuery });
  },
  destroyed() {
    bus.$emit('hideHeaderApplicationButton', false);
  },
  methods: {
    doOpenVotingModal(location) {
      this.$emit('doOpenVotingModal', {
        id: location.id,
        title: location.title.rendered,
        address: location.address,
        featuredImage: location.featuredImage,
      });
    },
    toggleDisplayedFinalists(type) {
      if (this.displayedFinalistType === type) {
        this.displayedFinalistType = null;
      } else {
        this.displayedFinalistType = type;
      }
    },
  },
};
</script>
