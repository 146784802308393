<template>
  <main v-if="allPagesLoaded && allSettingsLoaded" id="b4yp" role="main">
    <MainHeader @scrollToSection="scrollToSection" ref="MainHeader" />
    <router-view
      @doOpenSignupModal="doOpenSignupModal"
      @doOpenVotingModal="doOpenVotingModal"
      @scrollToSection="scrollToSection"
    />
    <MainFooter @doOpenSignupModal="doOpenSignupModal" />
    <SignUpModal :openSignupModal.sync="openSignupModal" v-if="openSignupModal" />
    <VotingModal
      v-if="openVotingModal"
      :park="park"
      :openVotingModal.sync="openVotingModal"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import MainHeader from './components/MainHeader.vue';
import MainFooter from './components/MainFooter.vue';

export default {
  name: 'B4YP',
  components: {
    MainHeader,
    MainFooter,
  },
  computed: {
    ...mapGetters({
      allPagesLoaded: 'allPagesLoaded',
      allSettingsLoaded: 'allSettingsLoaded',
    }),
  },
  data() {
    return {
      openSignupModal: false,
      openVotingModal: false,
      park: null,
      votingCallback: null,
    };
  },
  metaInfo() {
    return {
      title: 'The PetSafe® Unleashed™ Contest',
      meta: [
        {
          name: 'description',
          content: 'We’re celebrating 10 years of PetSafe® Unleashed™! '
            + 'That’s 10 years of wagging tails and smiling faces around the country. '
            + 'Since 2011, we’ve donated more than $1.5 million for more than 70 dog '
            + 'parks in 40 U.S. states.',
        },
        {
          property: 'og:title',
          content: 'The PetSafe® Unleashed™ Contest',
        },
        {
          property: 'og:site_name',
          content: 'PetSafe® Unleashed™',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'robots',
          content: 'index,follow',
        },
      ],
    };
  },
  methods: {
    doOpenSignupModal() {
      this.openSignupModal = true;
    },
    doOpenVotingModal(park) {
      this.openVotingModal = true;
      this.park = park;
    },
    scrollToSection(sectionId, behavior = 'smooth') {
      const element = document.getElementById(sectionId);
      if (!element) {
        // eslint-disable-next-line no-console
        console.error(`There is no element with an ID of ${sectionId}`);
        return;
      }

      const { MainHeader: MainHeaderComponent } = this.$refs;
      const headerOffset = MainHeaderComponent.$refs.fixedHeader.offsetTop + 140;
      const topPos = (element.getBoundingClientRect().top - headerOffset) + window.pageYOffset;

      window.scrollTo({
        top: topPos,
        behavior,
      });
    },
  },
};
</script>
