<template>
  <section :id="id" class="post-hero band">
      <div class="band--inner">
        <div
          class="angled-box w-4/5 p-8 m-auto text-center"
          :class="[ data.background_color ]"
          :style="styles"
        >
          <ContentHolder :content="data.content" />
        </div>
      </div>
    </section>
</template>

<script>
export default {
  name: 'AngledBox',
  props: {
    data: Object,
  },
  computed: {
    styles() {
      return this.data.y_offset ? `margin-top: ${this.data.y_offset}px` : null;
    },
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return this.data.id || this._uid;
    },
  },
};
</script>
