/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import api from '../../api';
import * as types from '../mutation-types';

// initial state
const state = {
  footer: {},
  header: {},
  loaded: false,
  page: null,
  postsPage: null,
};

// getters
const getters = {
  menu: (state) => state.header.menu,
  footerMenu: (state) => state.footer.menu,
  announcement: (state) => state.header.announcement,
  allSettingsLoaded: (state) => state.loaded,
  postsPage: (state) => parseInt(state.postsPage, 10),
};

// actions
const actions = {
  getAllSettings({ commit }) {
    api.getSettings((settings) => {
      commit(types.STORE_FETCHED_SETTINGS, { settings });
      commit(types.SETTINGS_LOADED, true);
    });
  },
};

// mutations
const mutations = {
  [types.STORE_FETCHED_SETTINGS](state, { settings }) {
    state.footer = settings.footer;
    state.header = settings.header;
    state.postsPage = settings['posts-page'];
  },

  [types.SETTINGS_LOADED](state, val) {
    state.loaded = val;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
