<template>
  <div class="form-container">
    <div class="md:hidden mobile-message flex flex-col p-16 text-center">
      <h2 class="mb-8 standout--dark-teal">Oops! <br />It looks like you’re on a mobile device.</h2>
      <p class="standout--dark-teal">
        Due to the file size required for downloading and uploading documents,
        we recommend submitting the PetSafe Unleashed™ online application on a
        desktop computer.
      </p>
      <p class="standout--dark-teal">
        If you do not have access to a desktop computer and would still like to
        submit an application, email us at petsafeunleashed@petsafe.net and we
        will send you an application that can be completed offline and submitted
        by email or postal mail.
      </p>
    </div>
    <div class="md:block hidden">
      <section
        v-if="formSubmitted || formError"
        id="submission-message"
        class="band"
      >
        <div class="band--inner p-8">
          <div
            v-if="formSubmitted"
            class="success-message flex flex-row items-center"
          >
            <Check class="w-1/6 mr-8" />
            <div class="w-5/6">
              <h2 class="mb-8 standout--dark-teal">Success!</h2>
              <h3 class="mb-8 standout--dark-teal">
                You've officially entered to win a dog park prize package.
              </h3>
              <p class="standout--dark-teal">
                Watch your email for updates on the status of your submission.
                And don't forget to encourage your community to vote November
                1–30!
              </p>
            </div>
          </div>
          <div
            v-if="formError"
            class="error-message flex flex-row items-center"
          >
            <Minus class="w-1/12 mr-8" />
            <div class="w-11/12">
              <h3 class="standout--dark-teal">There was an issue submitting your application.</h3>
              <p>{{ formError }}</p>
            </div>
          </div>
        </div>
      </section>
      <form v-if="!formSubmitted" @submit="sendApplication">
        <InputGroup label="Type of Dog Park">
          <LabeledRadio
            label="I am entering to win a prize package for a:"
            id="dogparktype"
            :options="dogParkTypes"
            v-model="formFields.dogParkType"
          />
        </InputGroup>
        <InputGroup label="Applicant Contact Information">
          <div class="flex flex-row">
            <LabeledInput
              class="w-1/2 mr-3"
              label="First name"
              id="firstname"
              :required="true"
              v-model="formFields.firstName"
            />
            <LabeledInput
              class="w-1/2 ml-3"
              label="Last name"
              id="lastname"
              :required="true"
              v-model="formFields.lastName"
            />
          </div>
          <LabeledInput
            class="mt-6"
            type="email"
            id="email"
            label="Email"
            :required="true"
            errorMessage="Please enter a valid email"
            :validate="validEmail"
            v-model="formFields.email"
          />
        </InputGroup>
        <InputGroup :label="parkLocationLabel">
          <LabeledInput
            v-if="isNewPark"
            class="mt-6"
            label="Community Name"
            key="communityname"
            id="communityname"
            :required="true"
            v-model="formFields.communityName"
          />
          <LabeledInput
            v-else
            class="mt-6"
            label="Park Name"
            key="parkname"
            id="parkname"
            :required="true"
            v-model="formFields.parkName"
          />
          <LabeledInput
            class="mt-6"
            label="Street Address (if known)"
            id="street"
            v-model="formFields.address.street"
          />
          <div class="flex flex-row mt-6">
            <LabeledInput
              class="w-2/5 mr-3"
              label="City"
              id="city"
              :required="true"
              v-model="formFields.address.city"
            />
            <LabeledSelect
              class="w-1/5 mx-3"
              label="State"
              id="state"
              :required="true"
              requiredErrorMessage="Select a state"
              :options="states"
              v-model="formFields.address.state"
            />
            <LabeledInput
              class="w-2/5 ml-3"
              label="Zip code"
              id="zipcode"
              :required="true"
              v-model="formFields.address.zipcode"
            />
          </div>
        </InputGroup>
        <InputGroup label="Additional Information">
          <template v-slot:default="{ isComplete }">
            <LabeledRadio
              v-if="!isNewPark"
              label="Previous PetSafe® Unleashed™ Winner?"
              key="previouswinner"
              id="previouswinner"
              :required="true"
              :options="yesNo"
              v-model="formFields.previousWinner"
            />
            <LabeledInput
              label="Community Population (if available)"
              class="w-3/4 mt-6"
              id="population"
              v-model="formFields.population"
            />
            <LabeledSelect
              :label="`Size of ${isNewPark ? 'proposed' : 'existing'} dog park`"
              class="w-3/4 mt-6"
              id="dogparksize"
              :required="true"
              :options="dogParkSizes"
              requiredErrorMessage="Let us know what size the dag park will be"
              v-model="formFields.dogParkSize"
            />
            <LabeledSelect
              label="How many dog parks are in your community?"
              class="mt-6"
              id="dogparkaccess"
              :required="true"
              :options="dogParkAccess"
              requiredErrorMessage="Tell us how many dog parks are available to your community"
              v-model="formFields.dogParkAccess"
            />
            <LabeledRadio
              label="Has your community raised additional funds for this dog park?"
              class="mt-10"
              id="additionalfunds"
              :required="true"
              :options="yesNo"
              v-model="formFields.additionalFunds"
            />
            <LabeledTextarea
              v-if="formFields.additionalFunds === yesNo.yes.value"
              label="Describe how you raised additional funds"
              class="mt-10"
              id="additionalfundshow"
              :required="true"
              :parentIsComplete="isComplete"
              v-model="formFields.additionalFundsHow"
            />
            <LabeledSelect
              :label="maintenanceLabel"
              class="mt-6"
              id="maintenanceresponsibility"
              :provideOtherOption="true"
              :otherExplanation.sync="
                formFields.maintenanceResponsibilityOtherExplanation
              "
              :passAlongOtherExplanation="
                updateMaintenanceResponsibilityOtherExplanation
              "
              :required="true"
              :options="maintenanceOptions"
              requiredErrorMessage="Tell us who will maintain the park"
              v-model="formFields.maintenanceResponsibility"
            />
            <LabeledRadio
              label="Does your community have any dedicated social
                media pages or websites for this dog park?"
              class="w-2/3 mt-10"
              id="dedicatedsocialmedia"
              :required="true"
              :options="yesNo"
              v-model="formFields.dedicatedSocialMedia"
            />
            <div class="mt-6" v-if="formFields.dedicatedSocialMedia === 'yes'">
              <LabeledInput
                v-for="(link, index) of formFields.sociaLinks"
                class="mt-6"
                :key="index"
                label="Paste Social or Web Link Here"
                :id="`sociallink${index}`"
                v-model="formFields.sociaLinks[index]"
              />
              <button
                class="link mt-3 ml-5"
                type="button"
                @click="addSocialLink"
              >
                Add another link
              </button>
            </div>
            <!-- eslint-disable -->
            <LabeledRadio label="Has your community ever entered the PetSafe® Unleashed™ contest in a previous year?"
              class="w-2/3 mt-10"
              key="previousApplicant"
              id="previousApplicant"
              :required="true"
              :options="yesNo"
              v-model="formFields.previousApplicant"
            />
            <!-- eslint-enable -->
            <LabeledTextarea
              label="Tell us why your community deserves a PetSafe® Unleashed™ prize package"
              class="mt-10"
              id="deserve"
              :required="true"
              v-model="formFields.deserve"
            />
          </template>
        </InputGroup>
        <MediaUploader
          widthClass="w-11/12"
          label="Letter of support from a civic leader"
          type="files"
          :required="true"
          v-model="formFields.civicLeaderForm"
        >
          Need the Civic Leader Support Template?
          <button
            type="button"
            class="link"
            @click="download('civic_leader_support_form')"
          >
            Click here to Download.
          </button>
        </MediaUploader>
        <MediaUploader
          widthClass="w-11/12"
          label="Confirmation of available land"
          type="files"
          :required="true"
          v-model="formFields.landVerificationForm"
        >
          Need the Land Verification Template?
          <button
            type="button"
            class="link"
            @click="download('land_verification_form')"
          >
            Click here to Download.
          </button>
        </MediaUploader>
        <MediaUploader
          widthClass="w-11/12"
          :label="imagesLabel"
          type="images"
          :multiple="true"
          v-model="formFields.images"
        />
        <InputGroup label="Terms &amp; Conditions Agreement">
          <div class="input-wrapper textarea-container mb-6">
            <textarea
              class="smaller-text"
              disabled
              v-model="termsAndConditions"
            ></textarea>
          </div>
          <p class="ml-5">
            <button
              type="button"
              class="link"
              v-if="this.termsConditionsPdf"
              @click="download('termsConditionsPdf')"
            >
              Click here to download the terms and conditions
            </button>
          </p>
          <LabeledCheckbox
            label="I understand and agree to be bound by the PetSafe Unleashed™ Contest Rules."
            class="mt-6"
            id="readtermsandconditions"
            :required="true"
            v-model="formFields.readTermsAndConditions"
          />
        </InputGroup>
        <InputGroup label="Code of Conduct Agreement">
          <div class="input-wrapper textarea-container mb-6">
            <textarea
              class="smaller-text"
              disabled
              v-model="codeOfConduct"
            ></textarea>
          </div>
          <p class="ml-5">
            <button
              type="button"
              class="link"
              v-if="this.data.code_of_conduct_pdf"
              @click="download('code_of_conduct_pdf')"
            >
              Click here to download the code of conduct
            </button>
          </p>
          <LabeledCheckbox
            label="I understand the PetSafe Unleashed™ Code of Conduct and agree to play nice as
              communities work to bring a dog park to their neighborhood."
            class="mt-6"
            id="readcodeofconduct"
            :required="true"
            v-model="formFields.readCodeOfConduct"
          />
        </InputGroup>
        <InputGroup label="Public Use Agreement">
          <LabeledCheckbox
            label="I understand and agree to be bound by the requirement that Prize
              Winners provide use of the dog park at no cost to the public in order to
              receive a Prize."
            class="mt-6"
            id="agreetopublicuse"
            :required="true"
            v-model="formFields.agreeToPublicUse"
          />
        </InputGroup>
        <InputGroup label="Naming Agreement" v-if="isNewPark">
          <LabeledCheckbox
            label="I understand and agree to be bound by the requirement that Prize
              Winners include “PetSafe” in name of dog park in order to receive a Prize."
            class="mt-6"
            id="agreetonaming"
            :required="true"
            v-model="formFields.agreeToNaming"
          />
        </InputGroup>
        <section class="band">
          <div class="band--inner px-20 my-16">
            <button
              class="button submit relative"
              :class="{ disabled: isSubmitting }"
            >
              {{ submitButtonLabel }}
              <span class="walking-dog">
                <font-awesome-icon size="lg" :icon="['fas', 'dog']" />
              </span>
            </button>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import { gmapApi } from 'gmap-vue';
import api from '../../api';
import states from '../../enums/states';
import {
  dogParkSizes,
  dogParkAccess,
  dogParkTypes,
  maintenanceResponsibilityNew,
  maintenanceResponsibilityExisting,
  yesNo,
} from '../../enums/application-dropdown-options';
import Check from '../../assets/svgs/check-circle.svg';
import Minus from '../../assets/svgs/minus-circle.svg';
import { bus } from '../../main';

export default {
  name: 'ApplicationForm',
  props: {
    data: Object,
  },
  components: {
    Check,
    Minus,
  },
  computed: {
    termsAndConditions() {
      return this.data.terms_conditions_agreement;
    },
    codeOfConduct() {
      return this.data.code_of_conduct_agreement;
    },
    address() {
      return this.formFields.address;
    },
    parkLocationLabel() {
      return this.isNewPark
        ? 'Prospective Park Location'
        : 'Exisiting Park Location';
    },
    maintenanceLabel() {
      return this.isNewPark
        ? 'Who will be responsible for maintenance of this dog park?'
        : 'Who is be responsible for maintenance of this dog park?';
    },
    maintenanceOptions() {
      return this.isNewPark
        ? maintenanceResponsibilityNew
        : maintenanceResponsibilityExisting;
    },
    isNewPark() {
      return this.formFields.dogParkType === dogParkTypes.new.value;
    },
    submitButtonLabel() {
      return this.isSubmitting
        ? 'Submtting entry...'
        : 'Submit my entry';
    },
    imagesLabel() {
      return this.isNewPark
        ? 'Images of available land'
        : 'Images of existing dog park';
    },
    termsConditionsPdf() {
      return {
        url: 'http://b4ypstage.wpengine.com/wp-content/uploads/2023/08/Finalized-and-Approved-PetSafe-Unleashed-Contest-Rules-8.24.23.docx.pdf',
        title: 'PetsafeUnleashedTermsAndConditions-2023.pdf',
      };
    },
    google: gmapApi,
  },
  mounted() {
    bus.$emit('hideHeaderApplicationButton', true);
  },
  destroyed() {
    bus.$emit('hideHeaderApplicationButton', false);
  },
  watch: {
    address: {
      handler(address) {
        const { city, state, zipcode } = address;
        if (city !== '' && state !== '' && zipcode !== '') {
          this.geocodeLocation(address);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      states,
      dogParkSizes,
      dogParkAccess,
      dogParkTypes,
      yesNo,
      terms_conditions_pdf: 'http://b4ypstage.wpengine.com/wp-content/uploads/2023/08/Finalized-and-Approved-PetSafe-Unleashed-Contest-Rules-8.24.23.docx.pdf',
      isSubmitting: false,
      formSubmitted: false,
      formError: false,
      formFields: {
        dogParkType: dogParkTypes.new.value,
        firstName: '',
        lastName: '',
        email: '',
        communityName: '',
        parkName: '',
        address: {
          street: '',
          city: '',
          state: '',
          zipcode: '',
        },
        population: '',
        previousWinner: '',
        dogParkSize: '',
        dogParkAccess: '',
        additionalFunds: '',
        additionalFundsHow: '',
        maintenanceResponsibility: '',
        maintenanceResponsibilityOtherExplanation: '',
        dedicatedSocialMedia: '',
        sociaLinks: [''],
        previousApplicant: '',
        deserve: '',
        civicLeaderForm: [],
        landVerificationForm: [],
        images: [],
        readTermsAndConditions: '',
        readCodeOfConduct: '',
        agreeToPublicUse: '',
        agreeToNaming: '',
      },
    };
  },
  methods: {
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    updateMaintenanceResponsibilityOtherExplanation(explanation) {
      this.formFields.maintenanceResponsibilityOtherExplanation = explanation;
    },
    addSocialLink() {
      this.formFields.sociaLinks.push('');
    },
    download(whichFile) {
      const file = this.data[whichFile] || this[whichFile];
      console.log(this[whichFile]);
      if (!file) {
        // eslint-disable-next-line no-console
        console.error(`File ${whichFile} not found`);
        return;
      }
      const a = document.createElement('a');
      a.href = file.url;
      a.download = file.title;
      a.target = '_blank';
      a.click();
    },
    sendApplication(e) {
      e.preventDefault();
      this.isSubmitting = true;
      const failedComponents = this.$children.filter(
        (c) => c.isCompleted === false,
      );
      const isFormValid = failedComponents.length === 0;
      if (isFormValid) {
        const { formFields } = this;
        api.submitApplication(formFields, ({ data: response }) => {
          if (response.success) {
            this.formSubmitted = true;
            this.$gtag.event('form_submission', {
              event_category: this.isNewPark ? 'new_park' : 'existing_park',
              event_label: this.formFields.communityName,
            });
          } else {
            this.formError = response.message;
          }
          setTimeout(() => {
            this.$emit('scrollToSection', 'submission-message');
          }, 100);
          this.isSubmitting = false;
        });
      } else {
        failedComponents.forEach((c) => {
          if (c.$options.name === 'MediaUploader') {
            c.validateInput(c.value);
          } else {
            c.$children.forEach((cc) => {
              if (cc.$options.name !== 'FormHeadline') {
                cc.validateInput(cc.value);
              }
            });
          }
        });
        const firstFailedComponent = failedComponents[0];
        this.$emit('scrollToSection', firstFailedComponent.id);
        const elem = firstFailedComponent.$el.querySelector('.band--inner');
        elem.classList.add('application--please-finish');
        this.isSubmitting = false;
        setTimeout(() => {
          elem.classList.remove('application--please-finish');
        }, 2000);
      }
    },
    async geocodeLocation(address) {
      if (!this.google) return;
      try {
        const geocoder = new this.google.maps.Geocoder();
        const { results } = await geocoder.geocode({
          address: Object.values(address).join(' '),
        });
        if (!results.length) return;
        const { location } = results[0].geometry;
        this.formFields.address.lat = location.lat();
        this.formFields.address.lng = location.lng();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  },
};
</script>
