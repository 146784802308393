<template>
  <div v-if="postLoaded" :id="postContent.id" class="post single md:pt-64 pt-8">
    <section class="band">
      <article class="band--inner px-4">
        <header class="md:flex-row flex flex-col items-center mt-8 mb-16">
          <h1 class="md:order-1 md:w-3/5 order-2 w-full pr-24">
            {{ postContent.title.rendered }}
          </h1>
          <figure class="featured-image md:order-2 md:mb-0 md:w-2/5 order-1 w-full mb-8">
            <img :src="postContent.featuredImage.source_url" :alt="postContent.featuredImage.alt" />
          </figure>
        </header>
        <main class="content clearfix">
          <ContentHolder :content="postContent.content.rendered" />
        </main>
      </article>
    </section>
    <PostsBand :data="postsBandData" layout="row" :key="postContent.id" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Post',
  computed: {
    ...mapGetters({
      post: 'post',
      postLoaded: 'postLoaded',
    }),

    postContent() {
      return this.postLoaded ? this.post(this.$route.params.postSlug) : null;
    },

    postsBandData() {
      const postId = this.postContent?.id;
      return {
        id: 'other-posts',
        posts_to_display: 'latest',
        per_page: 3,
        exclude: postId,
        headline: 'Other PetSafe Unleashed stories...',
      };
    },
  },
  watch: {
    $route: 'getPostFromStore',
    postContent(content) {
      if (this.postLoaded && !content) {
        this.$router.replaceWith({ name: 'FourOhFour' });
      }
    },
  },
  async created() {
    await this.getPostFromStore();
    if (this.postLoaded && !this.postContent) {
      const href = window.location.toString();
      this.$router.replace({ name: 'FourOhFour' });
      window.history.pushState({}, null, href);
    }
  },
  methods: {
    track() {
      this.$gtag.pageview({
        page_path: this.$route.params.postSlug,
      });
    },
    getPostFromStore() {
      this.$store.dispatch('getPost', this.$route.params.postSlug);
    },
  },
};
</script>
