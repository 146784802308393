<template>
  <Modal :openModal="openSignupModal" @performClose="performClose">
    <template>
      <div class="md:px-24 py-8 mb-8 text-center">
        <h3 class="heading-1 mb-4">Stay Up-To-Date</h3>

        <span v-if="!success">
          <p>
            Enter your email below to recieve important <br />
            PetSafe Unleashed™ announcements and news.
          </p>
          <div class="my-8">
            <p class="error" v-if="success === false">{{ message }}</p>
            <input
              ref="emailInput"
              v-model="email"
              v-on:keydown="onEmailKeydown"
              type="email"
              name="email"
              placeholder="Enter your email"
            />
          </div>
          <button class="button" v-on:click="submitEmail">Sign up</button>
        </span>
        <span class="success" v-else>
          <Checkmark />
          <p>You're email was successfully submitted!</p>
          <button
            class="button close-success mt-8"
          >
            Close
          </button>
        </span>
      </div>

      <p class="footnote md:px-6">
        By submitting this form, I agree to let PetSafe® brand send me
        news about Petsafe Unleashed™ and other helpful information.
        To learn more about our data use policies, see our
        <a href="https://www.petsafe.net/privacy" target="_blank">privacy policy</a>.
      </p>
    </template>
  </Modal>
</template>

<script>
import api from '../../api';
import Checkmark from '../../assets/svgs/check.svg';

export default {
  name: 'SignUpModal',
  components: {
    Checkmark,
  },
  props: {
    openSignupModal: Boolean,
  },
  data() {
    return {
      email: null,
      success: null,
    };
  },
  methods: {
    submitEmail() {
      if (!this.email) {
        this.success = false;
        this.message = 'You must enter an email.';
        return;
      }
      api.submitEmail(this.email, (request) => {
        this.email = null;
        this.success = request.success;
        this.message = request.message;
      });
    },
    onEmailKeydown(e) {
      if (e.key === 'Enter') {
        this.submitEmail();
      } else {
        this.success = null;
        this.message = '';
      }
    },
    performClose() {
      this.$emit('update:openSignupModal', false);
    },
  },
};
</script>
