<template>
  <div :id="id" class="page">
    <LocationsMap
      v-if="hero.display_map"
      @doOpenVotingModal="doOpenVotingModal"
    />
    <Hero v-else :data="hero" />
    <BlogIndex v-if="allSettingsLoaded && isPostsPage" />
    <component
      :is="module.acf_fc_layout"
      v-for="(module, index) in modules"
      :key="index"
      :data="module"
      @scrollToSection="scrollToSection"
      @doOpenVotingModal="doOpenVotingModal"
    ></component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BlogIndex from '../components/BlogIndex.vue';
import LocationsMap from '../components/LocationsMap.vue';

export default {
  name: 'Page',
  components: {
    BlogIndex,
    LocationsMap,
  },
  computed: {
    ...mapGetters({
      page: 'page',
      postsPageId: 'postsPage',
      allPagesLoaded: 'allPagesLoaded',
      allSettingsLoaded: 'allSettingsLoaded',
    }),

    pageContent() {
      return this.page(this.$route.params.pageSlug);
    },

    id() {
      return `page-${this.pageContent.id}`;
    },

    modules() {
      return this.pageContent?.acf?.modules?.page_pieces;
    },

    hero() {
      if (!this.pageContent) return {};
      const {
        acf: {
          developer_specific_fields: {
            hero_image: heroImage,
          },
          hero_section: heroSection,
        },
      } = this.pageContent;

      return {
        ...heroSection,
        background_image: heroImage,
      };
    },

    isPostsPage() {
      return this.postsPageId === this.pageContent.id;
    },
  },

  watch: {
    pageContent(content) {
      if (this.allPagesLoaded && !content) {
        this.$router.replace({ name: 'FourOhFour' });
      }
    },
  },

  created() {
    /*
    if (this.$route.params.pageSlug === 'application') {
      return this.$router.replace('/');
    }
    */
    document.body.classList.add(this.$route.params.pageSlug);
    if (this.allPagesLoaded && !this.pageContent) {
      const href = window.location.toString();
      this.$router.replace({ name: 'FourOhFour' });
      window.history.pushState({}, null, href);
      return null;
    }
    return null;
  },

  beforeRouteUpdate(to, from, next) {
    document.body.classList.remove(from.params.pageSlug);
    document.body.classList.add(to.params.pageSlug);
    next();
  },

  methods: {
    track() {
      this.$gtag.pageview({
        page_path: this.$route.params.pageSlug,
      });
    },
    scrollToSection(sectionId) {
      this.$emit('scrollToSection', sectionId);
    },
    doOpenVotingModal(options) {
      this.$emit('doOpenVotingModal', options);
    },
  },
};
</script>
