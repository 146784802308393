<template>
  <section :id="id" class="band application-section">
    <div class="band--inner p-8">
      <FormHeadline :completed="completed">{{label}}</FormHeadline>
      <div class="pl-12">
        <slot v-bind:isComplete="isComplete"></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'InputGroup',
  props: {
    label: String,
  },
  mounted() {
    this.isComplete();
  },
  computed: {
    completed() {
      return this.isCompleted;
    },
  },
  data() {
    return {
      // eslint-disable-next-line no-underscore-dangle
      id: `input-group--${this._uid}`,
      isCompleted: false,
    };
  },
  methods: {
    isComplete() {
      const validatableComponents = this.$children.filter(
        (c) => c.$options.name !== 'FormHeadline',
      );
      this.isCompleted = validatableComponents.filter((vc) => !vc.isValid).length === 0;
    },
  },
};
</script>
