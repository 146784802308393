const dogParkSizes = [
  {
    value: '< 1 acre',
    label: 'Less than 1 acre',
  },
  {
    value: '1-2 acres',
    label: '1-2 acres',
  },
  {
    value: '2-3 acres',
    label: '2-3 acres',
  },
  {
    value: '3-4 acres',
    label: '3-4 acres',
  },
  {
    value: '5+ acres',
    label: '5+ acres',
  },
];

const dogParkAccess = [
  {
    value: 'No dog parks',
    label: 'There are no dog parks in my community.',
  },
  {
    value: '1 dog park, more than 10 miles away',
    label: 'My community has access to one dog park, more than 10 miles away.',
  },
  {
    value: 'Multiple dog parks, more than 10 miles away',
    label: 'My community has access to multiple dog parks, more than 10 miles away.',
  },
  {
    value: '1 dog park, less than 10 miles away',
    label: 'My community has access to one dog park, less than 10 miles away.',
  },
  {
    value: 'Multiple dog parks, less than 10 miles away',
    label: 'My community has access to multiple dog parks or recreation spaces, less than 10 miles away.',
  },
];

const maintenanceResponsibilityNew = [
  {
    value: 'Local government',
    label: 'Our local government will help maintain the park.',
  },
  {
    value: 'Raise funds for maintenance',
    label: 'We will raise additional funds for park maintenance support.',
  },
  {
    value: 'Not Sure',
    label: 'Not Sure',
  },
];

const maintenanceResponsibilityExisting = [
  {
    value: 'Local government',
    label: 'Our local government does help maintain the park.',
  },
  {
    value: 'Raise funds for maintenance',
    label: 'We raise additional funds for park maintenance support.',
  },
  {
    value: 'Not Sure',
    label: 'Not Sure',
  },
];

const yesNo = {
  yes: {
    value: 'yes',
    label: 'Yes',
  },
  no: {
    value: 'no',
    label: 'No',
  },
};

const otherOption = [
  {
    value: 'other',
    label: 'Other',
  },
];

const dogParkTypes = {
  new: {
    value: 'New Park',
    label: 'New dog park',
  },
  existing: {
    value: 'Existing Park',
    label: 'Maintenance for an existing dog park',
  },
};

export {
  dogParkSizes,
  dogParkAccess,
  dogParkTypes,
  maintenanceResponsibilityNew,
  maintenanceResponsibilityExisting,
  yesNo,
  otherOption,
};
