<template>
  <Modal :openModal="openVotingModal && !!park" @performClose="performClose">
    <template v-slot:default="{ close }">
      <div class="voting-modal py-8 mb-8 text-center">
        <h3 class="md:mb-16 mb-6">Vote to help this community win!</h3>
        <span v-if="!success">
          <div class="park-details md:mb-16 flex flex-row items-center justify-center mb-6">
            <figure class="w-1/5">
              <img :src="park.featuredImage.source_url" :alt="park.featuredImage.alt_text" />
            </figure>
            <div class="pl-4 text-left">
              <h4 class="mb-0" v-html="park.title"></h4>
              <p>{{ park.address }}</p>
            </div>
          </div>
          <p>
           Enter your email and cast your vote for the day to help <br class="md:inline hidden">
           this community win a new dog park from PetSafe®.
          </p>
          <div class="md:w-2/3 md:my-8 w-full mx-auto my-4">
            <p
              class="error"
              :class="{ 'long-error-message': longErrorMessage }"
              v-if="success === false" v-html="message"
            ></p>
            <input
              ref="emailInput"
              v-model="email"
              v-on:keydown="onEmailKeydown"
              type="email"
              name="email"
              placeholder="Enter your email"
            />
          </div>
          <LabeledCheckbox
            label=
              "Yes, please send me information about PetSafe Unleashed,
              pets and pet ownership, product promotions and other helpful information."
            class="checkbox--small md:w-2/3 md:my-6 w-full my-3 ml-auto mr-auto text-left"
            id="marketingoptin"
            v-model="marketingOptIn"
          />
          <button
            class="button"
            v-on:click="submitVote"
            :disabled="isSubmitting"
          >
            {{ submitVoteLabel }}
            <font-awesome-icon
              v-if="isSubmitting"
              class="ml-4"
              :icon="['fas', 'spinner']"
              size="lg"
              spin
            />
          </button>
        </span>
        <span class="success" v-else>
          <VotedIcon />
          <p>
            Thank you for your vote! Come back tomorrow to vote again.<br>
            You can vote daily through November 30.
          </p>
          <button
            @click="close($event)"
            class="button close-success mt-8"
          >
            Close
          </button>
        </span>
      </div>
      <p class="footnote md:px-6">
        To learn more about our data use policies, see our <a href="https://www.petsafe.net/privacy" target="_blank">privacy policy</a>.
        By participating in this contest, I agree that I am eligible to take part
        in this contest and agree to be bound by the official contest rules.
      </p>
    </template>
  </Modal>
</template>

<script>
import api from '../../api';
import VotedIcon from '../../assets/svgs/vote-yes.svg';
import { bus } from '../../main';

export default {
  name: 'VotingModal',
  computed: {
    submitVoteLabel() {
      if (this.isSubmitting && this.castingCounter > 3) return 'Still working';
      if (this.isSubmitting && this.castingCounter > 6) return 'Almost there';
      return this.isSubmitting ? 'Casting your vote' : 'Cast your vote';
    },
  },
  components: {
    VotedIcon,
  },
  props: {
    openVotingModal: Boolean,
    park: Object,
  },
  data() {
    return {
      marketingOptIn: 'no',
      isSubmitting: false,
      email: null,
      success: null,
      castingCounter: 0,
      longErrorMessage: false,
    };
  },
  mounted() {
    this.$refs.emailInput.focus();
  },
  methods: {
    submitVote() {
      if (!this.email) {
        this.success = false;
        this.message = 'You must enter an email.';
        return;
      }
      this.isSubmitting = true;
      const castingInterval = setInterval(() => {
        this.castingCounter += 1;
      }, 1000);
      api.submitVote({
        email: this.email,
        location_id: this.park.id,
        marketing_opt_in: this.marketingOptIn,
      }, (request) => {
        const { success, message } = request;
        clearInterval(castingInterval);
        this.castingCounter = 0;
        this.email = null;
        this.success = success;
        this.message = message;
        if (message && message.includes('Oops')) {
          this.longErrorMessage = true;
        }
        this.isSubmitting = false;
        if (success) {
          this.marketingOptIn = false;
          bus.$emit('afterVoteCast', this.park.id);
        }
      });
    },
    onEmailKeydown(e) {
      if (e.key === 'Enter') {
        this.submitVote();
      } else {
        this.success = null;
        this.message = '';
      }
    },
    performClose() {
      this.$emit('update:openVotingModal', false);
    },
  },
};
</script>
