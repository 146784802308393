<template>
  <div v-if="locationLoaded" :id="id" class="location post single md:pt-64 pt-8">
    <section class="band">
      <article class="band--inner">
        <header class="md:flex-row md:px-16 flex flex-col items-center px-4 mt-8 mb-16">
          <div
            class="md:order-1 md:w-1/2 order-2 w-full pr-24"
          >
            <router-link class="button text-only inline-block mb-8 -ml-6" to="/locations">
              <font-awesome-icon :icon="['fas', 'arrow-left']" />
              Back to the map
            </router-link>
            <h1
              v-html="title"
            ></h1>
            <div v-if="isFinalist" class="finalist-badge flex flex-row items-center w-full my-16">
              <div class="w-1/5 pr-4">
                <AwardIcon />
              </div>
              <div class="w-4/5">
                <p>{{currentYear}} PetSafe® Unleashed™</p>
                <p class="heading-2">FINALIST</p>
              </div>
            </div>
            <div
              v-if="isFinalist && isVotingEnabled"
              class="vote flex flex-row items-center w-full"
            >
              <div class="w-1/5 pr-4">
                <VotedIcon v-if="didVoteForId" />
                <PreVote v-else />
              </div>
              <div class="w-4/5 pl-4">
                <button
                  class="button relative w-full"
                  @click="doOpenVotingModal"
                >
                  {{voteButtonLabel}}
                  <span class="walking-dog">
                    <font-awesome-icon size="lg" :icon="['fas', 'dog']" />
                  </span>
                </button>
              </div>
            </div>
          </div>
          <figure class="featured-image md:order-2 md:mb-0 md:w-1/2 order-1 w-full mb-8">
            <img
              :src="featuredImage.source_url"
              :alt="featuredImage.alt"
              class="relative z-10"
            />
            <AwardIcon />
          </figure>
        </header>
        <component
          :is="module.acf_fc_layout"
          v-for="(module, index) in modules"
          :key="index"
          :data="module"
        ></component>
      </article>
    </section>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { mapGetters } from 'vuex';
// eslint-disable-next-line import/no-cycle
import { bus } from '../main';
import AwardIcon from '../assets/svgs/award.svg';
import PreVote from '../assets/svgs/pre-vote.svg';
import VotedIcon from '../assets/svgs/vote-yes.svg';

export default {
  name: 'Location',
  components: {
    AwardIcon,
    PreVote,
    VotedIcon,
  },
  computed: {
    ...mapGetters({
      location: 'location',
      locationLoaded: 'locationLoaded',
    }),

    locationContent() {
      return this.locationLoaded ? this.location(this.$route.params.postSlug) : false;
    },

    id() {
      return `locatiion-${this.locationContent?.id}`;
    },

    modules() {
      return this.locationContent?.acf?.modules?.page_pieces;
    },

    hero() {
      return {
        background_image:
          this.locationContent?.featuredImage?.source_url,
      };
    },

    title() {
      return this.locationContent?.title?.rendered;
    },

    featuredImage() {
      return this.locationContent?.featuredImage || {};
    },

    isFinalist() {
      const yearAwarded = this.locationContent?.year_awarded;

      return parseInt(yearAwarded, 10) === this.currentYear && this.isVotingEnabled;
    },

    voteButtonLabel() {
      return this.didVoteForId ? 'Vote for this park again!' : 'Vote for this park!';
    },

    didVoteForId() {
      const votedForId = JSON.parse(window.localStorage.getItem(`voted_for_${this.locationContent.id}`));
      return this.justVotedForId || votedForId === true;
    },

    didVoteToday() {
      return this.justVotedForId || window.localStorage.getItem('voted_on') === this.today;
    },

    currentYear() {
      return new Date().getFullYear();
    },

    // will become backend setting
    isVotingEnabled() {
      return false;
    },
  },
  watch: {
    locationContent(content) {
      if (this.locationLoaded && !content) {
        this.$router.replace({ name: 'FourOhFour' });
      }
      return null;
    },
  },

  created() {
    if (this.locationLoaded && !this.locationContent) {
      const href = window.location.toString();
      this.$router.replace({ name: 'FourOhFour' });
      window.history.pushState({}, null, href);
    }
    return null;
  },
  mounted() {
    this.$gmapApiPromiseLazy().then(() => {
      this.getLocationFromStore();
    });
    bus.$on('afterVoteCast', (id) => {
      this.afterVoteCast(id);
    });
  },
  data() {
    return {
      today: new Date().toLocaleDateString('en-US'),
      justVotedForId: false,
    };
  },
  metaInfo() {
    const title = this.isFinalist
      ? `${this.currentYear} PetSafe® Unleashedk™ Finalist - Vote Today`
      : `${this.yearAwarded} PetSafe® Unleashed™ Recipient`;
    return {
      title: `${this.title} - PetSafe® Unleashed™`,
      meta: [
        {
          property: 'og:image',
          content: this.featuredImage?.source_url,
        },
        {
          property: 'og:url',
          content: window.location.toString(),
        },
        {
          property: 'og:title',
          content: title,
        },
      ],
    };
  },
  methods: {
    track() {
      this.$gtag.pageview({
        page_path: this.$route.params.postSlug,
      });
    },
    getLocationFromStore() {
      this.$store.dispatch('getLocation', this.$route.params.postSlug);
    },
    afterVoteCast(locationId) {
      this.justVotedForId = true;
      window.localStorage.setItem(`voted_for_${locationId}`, true);
      window.localStorage.setItem('voted_on', this.today);
    },
    doOpenVotingModal() {
      this.$emit('doOpenVotingModal', {
        id: this.locationContent.id,
        title: this.title,
        address: this.locationContent.address,
        featuredImage: this.featuredImage,
      });
    },
  },
};
</script>
