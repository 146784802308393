<template>
  <div class="modal--overlay" v-on:click="close">
    <div ref="modalContainer" class="modal--container">
      <section class="modal--content">
        <slot :close="close"></slot>
      </section>
      <button
        ref="closeButton"
        class="close"
      >
        <Close />
        <span class="sr-only">Close</span>
      </button>
    </div>
  </div>
</template>

<script>
import Close from '../../assets/svgs/times.svg';

export default {
  name: 'Modal',
  components: {
    Close,
  },
  props: {
    openModal: Boolean,
    performClose: Function,
  },
  mounted() {
    document.addEventListener('keyup', this.escCloseModal);
  },
  destroyed() {
    document.removeEventListener('keyup', this.escCloseModal);
  },
  methods: {
    escCloseModal(e) {
      if (e.key === 'Escape') {
        this.$emit('performClose');
      }
    },
    close(e) {
      const { modalContainer: container, closeButton } = this.$refs;
      if (!(container || closeButton)) return;
      const isInContainer = e.target === container || container.contains(e.target);
      const isCloseButton = e.target === closeButton
        || closeButton.contains(e.target)
        || e.target.classList.contains('close-success');
      const shouldCloseModal = !isInContainer || isCloseButton;
      if (shouldCloseModal) {
        this.$emit('performClose');
      }
    },
  },
};
</script>
