<template>
  <div class="input-error-container ml-5" :class="{ 'has-error': requiredError }">
    <div class="input-wrapper checkbox" :class="{ 'has-input': value !== '' }">
      <label :id="`${id}-main-label`" class="main-label">
        <input
          ref="checkbox"
          type="checkbox"
          :name="id"
          :value="true"
          @change="updateValue()"
        >
        <span class="indicator"></span>
        <span class="label">{{label}}</span>
      </label>
    </div>
    <p v-if="requiredError" class="error-message">{{requiredErrorMessage}}</p>
  </div>
</template>

<script>
export default {
  name: 'LabeledCheckbox',
  props: {
    label: String,
    id: String,
    value: String,
    required: Boolean,
    requiredErrorMessage: {
      type: String,
      default: 'This field is required',
    },
  },
  mounted() {
    if (this.$parent.isComplete) this.$parent.isComplete();
  },
  destroyed() {
    this.$emit('input', '');
  },
  data() {
    return {
      validationError: false,
      requiredError: false,
      isValid: !(this.required),
    };
  },
  methods: {
    updateValue() {
      const { checked } = this.$refs.checkbox;
      const displayValue = checked ? 'yes' : 'no';
      this.requiredError = false;
      this.$emit('input', displayValue);
      this.validateInput(displayValue);
    },
    validateInput(value) {
      if (this.required && (value === 'no' || value === '')) {
        this.requiredError = true;
        this.isValid = false;
      } else {
        this.isValid = true;
      }
      if (this.$parent.isComplete) this.$parent.isComplete();
    },
  },
};
</script>
