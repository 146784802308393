export default function LocationMarkerIconBase(doRedIcon) {
  const gmaps = window.google?.maps;
  if (!gmaps) return null;
  return {
    path: 'M 9 0 C 4.031 0 0 4.031 0 9 C 0 12.628 1.266 13.64 8.075 23.516 A 1.126 1.126 0 0 0 9.925 23.516 C 16.735 13.64 18 12.628 18 9 C 18 4.031 13.969 0 9 0 Z M 7.992 3.04 C 8.114 2.62 8.48 2.25 8.874 2.183 C 8.989 2.156 9.107 2.139 9.224 2.133 C 9.367 2.136 9.508 2.165 9.641 2.217 C 9.991 2.534 10.474 2.903 10.574 3.471 C 10.712 4.246 9.802 4.827 9.186 4.799 C 8.416 4.766 7.748 3.885 7.992 3.039 Z M 5.443 4.538 C 5.75 4.368 6.093 4.274 6.443 4.267 C 6.932 4.267 7.363 4.497 7.407 5.132 C 7.458 5.904 7.092 6.295 6.335 6.387 C 5.893 6.441 5.658 6.333 5.502 5.949 C 5.331 5.525 5.164 4.831 5.443 4.538 Z M 4.427 7.141 C 4.496 6.993 4.667 6.933 4.863 6.933 C 5.136 6.933 5.454 7.05 5.601 7.205 C 5.956 7.58 5.849 8.351 5.543 8.728 C 4.561 9.939 3.887 7.543 4.427 7.141 Z M 12.991 12.611 C 12.346 13.962 11.428 13.427 10.581 13.121 C 9.605 12.769 8.218 12.385 7.372 13.431 C 6.999 13.891 6.778 14.592 6.302 14.842 C 5.798 15.108 5.096 14.801 5.346 13.802 C 5.372 13.529 5.394 13.392 5.436 13.075 C 5.487 12.694 5.553 12.315 5.636 11.939 C 5.857 10.927 6.068 9.846 6.432 8.918 C 6.846 7.859 7.217 6.818 8.006 6.252 C 8.363 5.996 8.743 5.867 9.126 5.867 A 1.87 1.87 0 0 1 10.157 6.194 C 11.077 6.798 11.896 7.822 12.479 9.036 C 12.976 10.072 13.582 11.371 12.991 12.61 Z M 12.5 6.39 C 11.767 6.32 11.256 6.018 11.137 5.27 C 11.014 4.498 11.525 3.733 12.29 3.733 C 12.36 3.733 12.43 3.74 12.5 3.753 C 12.76 3.801 12.821 3.87 13.04 4.033 C 14.038 4.779 14.14 6.549 12.5 6.39 Z',
    fillOpacity: 1,
    strokeWeight: 0,
    scale: 1.25,
    size: new gmaps.Size(18, 24),
    origin: new gmaps.Point(0, 0),
    anchor: new gmaps.Point(9, 24),
    fillColor: doRedIcon ? '#fc3319' : '#263746',
  };
}
