<template>
  <section :id="id" class="video-embed band pt-8">
    <div
      class="band--inner md:px-16 px-4 py-8"
      :class="{
        'md:mt-32': data.collapse_bottom_spacing,
        'md:my-32': !data.collapse_bottom_spacing
      }"
    >
      <div class="video-embed--video-wrapper" v-html="data.video"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VideoEmbed',
  props: {
    data: Object,
  },
  computed: {
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return this.data.id || this._uid;
    },
  },
};
</script>
