import axios from 'axios';
import SETTINGS from '../settings';

export default {
  getPages(cb) {
    return axios
      .get(`${SETTINGS.WP_API_BASE_PATH}pages?per_page=100`)
      .then((response) => {
        cb(response.data, response.headers);
      })
      .catch((e) => {
        cb(e);
      });
  },

  getPageForPosts(cb) {
    return axios
      .get(`${SETTINGS.CUSTOM_API_BASE_PATH}page-for-posts`)
      .then((response) => {
        cb(response.data, response.headers);
      })
      .catch((e) => {
        cb(e);
      });
  },

  getPage(id, cb) {
    if (!Number.isInteger(id) || !id) return false;

    return axios
      .get(`${SETTINGS.WP_API_BASE_PATH}pages/${id}`)
      .then((response) => {
        cb(response.data, response.headers);
      })
      .catch((e) => {
        cb(e);
      });
  },

  getPosts(args, cb) {
    return axios
      .get(`${SETTINGS.WP_API_BASE_PATH}posts`, { params: { ...args, _embed: true } })
      .then((response) => {
        cb(response.data, response.headers);
      })
      .catch((e) => {
        cb(e);
      });
  },

  getPost(id, cb) {
    return axios
      .get(`${SETTINGS.WP_API_BASE_PATH}post/${id}`, { params: { _embed: true } })
      .then((response) => {
        cb(response.data, response.headers);
      })
      .catch((e) => {
        cb(e);
      });
  },

  getSettings(cb) {
    return axios
      .get(`${SETTINGS.CUSTOM_API_BASE_PATH}settings`)
      .then((response) => {
        cb(response.data, response.headers);
      })
      .catch((e) => {
        cb(e);
      });
  },

  submitEmail(email, cb) {
    const {
      username,
      password,
    } = SETTINGS;
    axios
      .post(`${SETTINGS.CUSTOM_API_BASE_PATH}submit-email`, { email }, {
        auth: {
          username,
          password,
        },
      })
      .then((response) => {
        cb(response.data, response.headers);
      })
      .catch((e) => {
        cb(e);
      });
  },

  submitVote(args, cb) {
    const {
      username,
      password,
    } = SETTINGS;
    axios
      .post(`${SETTINGS.CUSTOM_API_BASE_PATH}submit-vote`, args, {
        auth: {
          username,
          password,
        },
      })
      .then((response) => {
        cb(response.data, response.headers);
      })
      .catch((e) => {
        cb(e);
      });
  },

  getLocations(args, cb) {
    return axios
      .get(`${SETTINGS.WP_API_BASE_PATH}locations`, { params: { ...args, _embed: true } })
      .then((response) => {
        cb(response.data, response.headers);
      })
      .catch((e) => {
        cb(e);
      });
  },

  getApplicationFields(cb) {
    return axios
      .get(`${SETTINGS.CUSTOM_API_BASE_PATH}application-fields`)
      .then((response) => {
        cb(response.data, response.headers);
      })
      .catch((e) => {
        cb(e);
      });
  },

  sendFileToWP(data, cb) {
    const {
      username,
      password,
    } = SETTINGS;
    return axios
      .post(`${SETTINGS.WP_API_BASE_PATH}media`, data, {
        auth: {
          username,
          password,
        },
      })
      .then((response) => {
        cb(response);
      })
      .catch((e) => {
        cb(e);
      });
  },

  removeFileFromWP(id, cb) {
    const {
      username,
      password,
    } = SETTINGS;
    return axios
      .delete(`${SETTINGS.WP_API_BASE_PATH}media/${id}?force=true`, {
        auth: {
          username,
          password,
        },
      })
      .then((response) => {
        cb(response);
      })
      .catch((e) => {
        cb(e);
      });
  },

  submitApplication(args, cb) {
    const {
      username,
      password,
    } = SETTINGS;
    return axios
      .post(`${SETTINGS.CUSTOM_API_BASE_PATH}submit-application`, { ...args }, {
        auth: {
          username,
          password,
        },
      })
      .then((response) => {
        cb(response);
      })
      .catch((e) => {
        cb(e);
      });
  },
};
