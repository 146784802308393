<template>
  <section
    :id="id"
    class="secondary-hero band md:px-8 md:mb-0 pt-24 pb-48 pl-4 pr-8 mb-64"
    :class="[ data.background_color ]"
  >
    <div class="band--inner">
      <div class="md:w-1/2 lg:px-12 xl:px-16 w-full">
        <ContentHolder :content="data.content" />
        <button
          v-if="data.display_sign_up_modal_trigger"
          class="button block"
          :class="[data.trigger_alignment]"
          @click="doOpenSignupModal"
        >
          {{data.trigger_label}}
        </button>
      </div>
    </div>
    <div class="image">
      <img src="https://barkforyourpark.petsafe.com/wp-content/uploads/2021/03/labrador_pet_no_mask.png" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContentWithDogImage',
  props: {
    data: Object,
  },
  computed: {
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return this.data.id || this._uid;
    },
  },
  methods: {
    doOpenSignupModal() {
      this.$emit('doOpenSignupModal');
    },
  },
};
</script>
