<template v-if="allPagesLoaded">
  <div id="home" class="page-container">
    <Hero :data="hero" />
    <component
      :is="module.acf_fc_layout"
      v-for="(module, index) in modules"
      :key="index"
      :data="module"
      @doOpenSignupModal="doOpenSignupModal"
      @scrollToSection="scrollToSection"
    ></component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  computed: {
    ...mapGetters({
      page: 'page',
      allPagesLoaded: 'allPagesLoaded',
    }),

    pageContent() {
      return this.page('home');
    },

    modules() {
      return this.pageContent?.acf?.modules?.page_pieces;
    },

    hero() {
      const {
        acf: {
          developer_specific_fields: {
            hero_image: heroImage,
          },
          hero_section: heroSection,
        },
      } = this.pageContent;

      return {
        ...heroSection,
        background_image: heroImage,
      };
    },
  },
  methods: {
    track() {
      this.$gtag.pageview({
        page_path: '/',
      });
    },
    doOpenSignupModal() {
      this.$emit('doOpenSignupModal');
    },
    scrollToSection(sectionId) {
      this.$emit('scrollToSection', sectionId);
    },
  },
};
</script>
