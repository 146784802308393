<template>
  <footer v-if="allSettingsLoaded">
    <div class="footer-top">
      <div class="footer-clouds px-16 pb-8">
        <Clouds />
      </div>
      <div class="footer-park">
        <Park />
      </div>
    </div>
    <div class="footer-bottom band--inner">
      <nav>
        <router-link to="/" class="logo">
          <img
            src="../assets/images/PetSafe®-Unleashed-Logo_RGB.png"
            class="mt-8"
            alt="Petsafe Unleashed"
          />
        </router-link>
        <ul>
          <li v-for="(link, index) in menu.items" :key="index">
            <router-link :to="link.name" :title="link.title">{{
              link.label
            }}</router-link>
          </li>
        </ul>
        <button
          v-if="callToAction.display_cta"
          v-on:click="$emit('doOpenSignupModal')"
          class="button"
        >
          {{ callToAction.label }}
        </button>
      </nav>
      <p class="text-center">
        Email Us at <a href="mailto:petsafeunleashed@petsafe.net">petsafeunleashed@petsafe.net</a>
      </p>
      <div class="copyright md:items-center flex flex-row">
        <p class="flex-grow">
          ©{{currentYear}} Radio Systems Corporation. All rights reserved. PetSafe Unleashed™
          is a trademark of Radio Systems Coporation's PetSafe® brand.
        </p>
        <ul class="mt-0">
          <li v-for="(link, index) in footerMenu.items" :key="index">
            <a
              v-if="link.type === 'custom'"
              :href="link.name"
              :title="link.title"
              target="_blank"
            >
              {{link.label}}
            </a>
            <router-link v-else :to="link.name" :title="link.title">{{
              link.label
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import Clouds from '../assets/svgs/clouds.svg';
import Park from '../assets/svgs/park-silhouette.svg';

export default {
  name: 'MainFooter',
  components: {
    Clouds,
    Park,
  },
  computed: {
    ...mapGetters({
      menu: 'menu',
      footerMenu: 'footerMenu',
      allSettingsLoaded: 'allSettingsLoaded',
    }),
    callToAction() {
      return this.footerMenu['call-to-action'] || {};
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
