<template>
  <section class="hero band" :class="{ 'empty-hero': !hasData }" :style="heroStyles">
    <div class="band--inner">
      <div v-if="hasData" class="md:w-1/2">
        <div
          v-if="data.display_contest_logo"
          class="svg-shadow md:w-5/6 md:mb-8 md:mt-0 w-2/3 mx-auto mt-24 mb-1"
        >
          <img src="../../assets/images/PetSafe®-Unleashed-Logo_RGB.png" alt="Petsafe Unleashed" />
        </div>
        <h1 v-else>{{ data.headline }}</h1>
        <p v-html="data.copy"></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    data: Object,
  },
  computed: {
    heroStyles() {
      return this.data.background_image
        ? `background-image: url(${this.data.background_image})`
        : null;
    },
    hasData() {
      return this.data.headline !== '' || this.data.copy !== '' || this.data.display_content_logo || this.data.background_image;
    },
  },
};
</script>
