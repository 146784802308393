const files = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf: 'application/pdf',
};

const images = {
  jpg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  tiff: 'image/tiff',
  webp: 'image/webp',
};

export default {
  files,
  images,
};
