<template>
  <section :id="id" class="accordion-list band">
    <div class="band--inner md:px-16 px-4 py-8">
      <dl>
        <template v-for="(item, index) in items">
          <dt class="mb-8" :key="index">
            <button
              class="flex flex-row w-full px-2 py-4 text-left"
              v-on:click="showContentFor(item)"
            >
              <font-awesome-icon
                class="mr-4 transition-transform duration-300 transform"
                :class="{ '-rotate-90': !item.show }"
                :icon="['fas', 'chevron-down']"
                size="lg"
              />
              <h3>{{ item.title }}</h3>
            </button>
          </dt>
          <transition name="slide-down" :key="index">
            <dd class="flex flex-row w-full px-2 py-4 mb-8 -mt-8" v-if="item.show" :key="index">
              <ContentHolder class="md:pl-12 pl-10" :content="item.content" />
            </dd>
          </transition>
        </template>
      </dl>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AccordionList',
  props: {
    data: Object,
  },
  data() {
    return {
      accordionItems: this.data.items,
    };
  },
  computed: {
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return this.data.id || this._uid;
    },
    items() {
      return this.accordionItems;
    },
  },
  methods: {
    showContentFor(item) {
      this.accordionItems = this.items.map((i) => {
        if (i === item) {
          // eslint-disable-next-line no-param-reassign
          i.show = !i.show;
        }
        return i;
      });
    },
  },
};
</script>
