<template>
  <section
    :id="id"
    class="content band"
    :class="{
      'content__angled-boxes-background': data.angled_boxes_background,
      [data.background_color]: data.angled_boxes_background
    }"
  >
    <div
      class="band--inner md:px-16 px-4 py-8"
      :class="{
        'mt-32': data.collapse_bottom_spacing && !data.collapse_top_spacing,
        'md:my-32': !data.collapse_bottom_spacing && !data.collapse_top_spacing,
      }"
    >
      <ContentHolder :content="data.content" />
      <button
        v-if="data.display_jump_to_trigger"
        class="button block mt-16"
        :class="[data.trigger_alignment]"
        v-on:click="performClickAction"
      >
        {{data.trigger_label}}
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContentBand',
  props: {
    data: Object,
  },
  computed: {
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return this.data.id || this._uid;
    },
  },
  methods: {
    performClickAction() {
      if (this.data.jump_to_page) {
        const slug = new URL(this.data.jump_to_page).pathname;
        this.$router.push(slug);
      } else if (this.data.trigger_label.includes('Sign up')) {
        this.$emit('doOpenSignupModal');
      } else {
        this.$emit('scrollToSection', this.data.where_to_jump_to);
      }
    },
  },
};
</script>
