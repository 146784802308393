<template>
  <section class="band blog-index px-8 pt-16">
    <div class="band--inner">
      <article v-if="featuredPost && featuredLoaded"
        class="blog-index--featured md:flex-row flex flex-col items-center"
      >
        <figure class="md:w-1/3 w-full">
          <img :src="featuredPost.featuredImage.source_url" :alt="featuredPost.featuredImage.alt" />
        </figure>
        <div class="blog-index--featured__details md:w-2/3 md:pl-24 md:mt-0 w-full mt-16">
          <h3 class="heading-3">{{featuredPost.title.rendered}}</h3>
          <ContentHolder class="block my-8" :content="featuredPost.excerpt.rendered" />
          <router-link :to="featuredPost.slug" class="button">Read more</router-link>
        </div>
      </article>
      <section class="posts posts-band--layout__row my-32">
        <div class="articles">
          <article
            class="flex flex-row items-center"
            v-for="(post, index) in posts"
            :key="index"
          >
            <div class="figure-wrapper">
              <figure class="overflow-hidden">
                <img :src="post.featuredImage.source_url" :alt="post.featuredImage.alt" />
              </figure>
              <router-link :to="post.slug" class="md:hidden button">Read More</router-link>
            </div>
            <div class="content">
              <h3 class="heading-3 mb-8">{{ post.title.rendered }}</h3>
              <ContentHolder :content="post.excerpt.rendered" />
              <router-link :to="post.slug" class="md:inherit button hidden">Read More</router-link>
            </div>
          </article>
        </div>
        <button
          class="button text-only md:ml-auto md:mr-0 block mx-auto mt-16"
          v-on:click="populatePosts(3)"
          :disabled="!indexLoaded"
        >
          {{showMoreLabel}}
        </button>
      </section>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BlogIndex',
  computed: {
    ...mapGetters({
      stickyPosts: 'getStickyPosts',
      posts: 'getIndexPosts',
      featuredLoaded: 'postsLoaded',
      indexLoaded: 'morePostsLoaded',
    }),
    featuredPost() {
      return this.stickyPosts(1);
    },
    showMoreLabel() {
      return this.indexLoaded ? 'Show More...' : 'Loading...';
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  mounted() {
    this.populatePosts();
    this.$store.dispatch('getPosts', { sticky: true, per_page: 1 });
  },
  methods: {
    populatePosts(perPage = 9) {
      this.$store.dispatch('getIndexPosts', {
        per_page: perPage,
        page: this.currentPage,
      });
      this.currentPage += 1;
    },
  },
};
</script>
