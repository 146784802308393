<template>
  <section :id="id" class="icon-columns band">
    <div
      class="band--inner md:px-16 px-4 py-8"
      :class="{ 'mt-32': data.collapse_bottom_spacing, 'md:my-32': !data.collapse_bottom_spacing }"
    >
      <ul>
        <li v-for="(item, index) in data.items" :key="index">
          <img :src="item.icon.url" :alt="item.icon.alt" role="presentation" />
          <ContentHolder :content="item.content" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'IconColumns',
  props: {
    data: Object,
  },
  computed: {
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return this.data.id || this._uid;
    },
  },
};
</script>
