var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"content band",class:( _obj = {
    'content__angled-boxes-background': _vm.data.angled_boxes_background
  }, _obj[_vm.data.background_color] = _vm.data.angled_boxes_background, _obj ),attrs:{"id":_vm.id}},[_c('div',{staticClass:"band--inner md:px-16 px-4 py-8",class:{
      'mt-32': _vm.data.collapse_bottom_spacing && !_vm.data.collapse_top_spacing,
      'md:my-32': !_vm.data.collapse_bottom_spacing && !_vm.data.collapse_top_spacing,
    }},[_c('ContentHolder',{attrs:{"content":_vm.data.content}}),(_vm.data.display_jump_to_trigger)?_c('button',{staticClass:"button block mt-16",class:[_vm.data.trigger_alignment],on:{"click":_vm.performClickAction}},[_vm._v(" "+_vm._s(_vm.data.trigger_label)+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }