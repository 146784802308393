/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import api from '../../api';
import * as types from '../mutation-types';

const createPostSlug = (post, strip = '/post/') => {
  const slug = post.link.replace(
    `${window.location.protocol}//${process.env.VUE_APP_API_ORIGIN}`,
    '',
  );
  const postName = slug.replace(strip, '').replace('/', '');

  return {
    slug,
    postName,
  };
};

export function processPosts(posts, strip = '/post/') {
  if (!posts.length) {
    return [];
  }
  return posts.map((post) => {
    const {
      _embedded: embedded,
    } = post;
    // eslint-disable-next-line no-underscore-dangle
    const featuredImage = embedded && embedded['wp:featuredmedia'] ? embedded['wp:featuredmedia'][0] : {};
    // eslint-disable-next-line no-underscore-dangle
    const author = embedded ? embedded.author : null;
    const slug = createPostSlug(post, strip);

    return {
      ...post,
      ...slug,
      featuredImage,
      author,
    };
  });
}

function sortLimitAndReturn(posts, limit = 10) {
  const processed = Object
    .values(posts)
    .sort((a, b) => a.committed_at - b.committed_at)
    .slice(0, limit);
  return limit === 1 ? processed[0] : processed;
}

// initial state
const state = {
  all: {},
  stickyPosts: {},
  nonStickyPosts: {},
  latestRetrieved: [],
  indexPosts: [],
  morePostsLoaded: false,
  postsLoaded: false,
  postLoaded: false,
};

// getters
const getters = {
  getPosts: (state) => (limit) => {
    if (!limit || !Number.isInteger(limit) || typeof limit === 'undefined') {
      return state.all;
    }
    return sortLimitAndReturn(state.all, limit);
  },
  getNonStickyPosts: (state) => (limit) => {
    if (!limit || !Number.isInteger(limit) || typeof limit === 'undefined') {
      return state.nonStickyPosts;
    }
    return sortLimitAndReturn(state.nonStickyPosts, limit);
  },
  getStickyPosts: (state) => (limit) => {
    if (!limit || !Number.isInteger(limit) || typeof limit === 'undefined') {
      return state.stickyPosts;
    }
    return sortLimitAndReturn(state.stickyPosts, limit);
  },
  getPostsForPostBand: (state) => (limit) => {
    const posts = [...Object.values(state.stickyPosts), ...state.latestRetrieved];
    return posts.slice(0, limit);
  },
  getLatestPostsRetrieved: ({ latestRetrieved }) => latestRetrieved,
  getIndexPosts: ({ indexPosts }) => indexPosts,
  post: (state) => (name) => {
    if (typeof name === 'undefined') return null;
    if (typeof name !== 'string') throw new Error('passed name must be a string');

    const post = state.all[name];
    return post;
  },
  morePostsLoaded: ({ morePostsLoaded }) => morePostsLoaded,
  postsLoaded: ({ postsLoaded }) => postsLoaded,
  postLoaded: ({ postLoaded }) => postLoaded,
};

// actions
const actions = {
  getPosts({ commit }, args) {
    commit(types.POSTS_LOADED, false);
    api.getPosts(args, (posts) => {
      if (posts.length < 1) {
        commit(types.POSTS_LOADED, true);
        return;
      }
      commit(types.STORE_FETCHED_POSTS, { posts: processPosts(posts) });
      commit(types.POSTS_LOADED, true);
    });
  },
  getIndexPosts({ commit }, args) {
    commit(types.MORE_POSTS_LOADED, false);
    api.getPosts(args, (posts) => {
      if (posts.length < 1) {
        commit(types.POSTS_LOADED, true);
        return;
      }

      commit(types.LOAD_MORE_POSTS, { posts: processPosts(posts), page: args.page });
      commit(types.STORE_FETCHED_POSTS, { posts: processPosts(posts) });
      commit(types.MORE_POSTS_LOADED, true);
    });
  },
  getPost({ commit, state }, postName) {
    commit(types.POST_LOADED, false);
    const post = state.all[postName];
    if (post) {
      commit(types.POST_LOADED, true);
    } else {
      api.getPosts({ slug: postName }, (posts) => {
        if (posts.length < 1) {
          commit(types.POST_LOADED, true);
          return;
        }
        commit(types.STORE_FETCHED_POSTS, { posts: processPosts(posts) });
        commit(types.POST_LOADED, true);
      });
    }
  },
};

// mutations
const mutations = {
  [types.STORE_FETCHED_POSTS](state, { posts }) {
    state.latestRetrieved = posts;
    posts.map((post) => {
      const name = post.postName;
      const removeFrom = post.sticky ? 'nonStickyPosts' : 'stickyPosts';
      const addTo = post.sticky ? 'stickyPosts' : 'nonStickyPosts';

      post.committed_at = Date.now();

      state.all[name] = post;
      // add to appropriate post holder
      state[addTo][name] = post;
      // if stickiness changed remove from previous post holder
      if (state[removeFrom][name]) delete state[removeFrom][name];

      return post;
    });
  },

  [types.LOAD_MORE_POSTS](state, { posts, page }) {
    const startingPosts = page === 1 ? [] : state.indexPosts;
    state.indexPosts = startingPosts.concat(posts);
  },

  [types.POSTS_LOADED](state, val) {
    if (!val) {
      state.latestRetrieved = [];
    }
    state.postsLoaded = val;
  },

  [types.POST_LOADED](state, val) {
    if (!val) {
      state.latestRetrieved = [];
    }
    state.postLoaded = val;
  },

  [types.MORE_POSTS_LOADED](state, val) {
    state.morePostsLoaded = val;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
