<template>
  <div class="input-error-container ml-5" :class="{ 'has-error': requiredError }">
    <div class="input-wrapper radio-group" :class="{ 'has-input': value !== '' }">
      <label :id="`${id}-main-label`" class="main-label">{{label}}</label>
      <div
        ref="radiobuttonhome"
        class="radio-inputs flex flex-row items-center"
        :aria-labelledby="`${id}-main-label`"
      >
        <label v-for="option in options" :key="option.value">
          <input
            type="radio"
            :name="id"
            :value="option.value"
            :checked="option.value === value"
            @change="updateValue()"
          >
          <span class="indicator"></span>
          <span class="label">{{option.label}}</span>
        </label>
      </div>
    </div>
    <p v-if="requiredError" class="error-message">{{requiredErrorMessage}}</p>
  </div>
</template>

<script>
export default {
  name: 'LabeledRadio',
  props: {
    label: String,
    id: String,
    value: String,
    options: [Array, Object],
    required: Boolean,
    requiredErrorMessage: {
      type: String,
      default: 'This field is required',
    },
  },
  mounted() {
    if (this.$parent.isComplete) this.$parent.isComplete();
  },
  destroyed() {
    this.$emit('input', '');
  },
  data() {
    return {
      validationError: false,
      requiredError: false,
      isValid: !(this.required),
    };
  },
  methods: {
    updateValue() {
      const { value } = this.$refs.radiobuttonhome.querySelector(':checked');
      this.requiredError = false;
      this.$emit('input', value);
      this.validateInput(value);
    },
    validateInput(value) {
      if (this.required && value === '') {
        this.requiredError = true;
        this.isValid = false;
      } else {
        this.isValid = true;
      }
      if (this.$parent.isComplete) this.$parent.isComplete();
    },
  },
};
</script>
