export default {
  ORIGIN: `https://${process.env.VUE_APP_API_ORIGIN}`,
  WP_API_BASE_PATH: `//${
    process.env.VUE_APP_API_ORIGIN || 'bark-for-your-park.petsafe.com'
  }/wp-json/wp/v2/`,
  CUSTOM_API_BASE_PATH: `//${
    process.env.VUE_APP_API_ORIGIN || 'bark-for-your-park.petsafe.com'
  }/wp-json/b4yp/v1/`,
  username: process.env.VUE_APP_WP_USERNAME,
  password: process.env.VUE_APP_WP_PASSWORD,
};
