export const STORE_FETCHED_CATEGORIES = 'STORE_FETCHED_CATEGORIES';
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED';

export const STORE_FETCHED_PAGES = 'STORE_FETCHED_PAGES';
export const PAGES_LOADED = 'PAGES_LOADED';

export const STORE_FETCHED_POSTS = 'STORE_FETCHED_POSTS';
export const POSTS_LOADED = 'POSTS_LOADED';
export const POST_LOADED = 'POST_LOADED';
export const LOAD_MORE_POSTS = 'LOAD_MORE_POSTS';
export const MORE_POSTS_LOADED = 'MORE_POSTS_LOADED';
export const STORE_POST_HEADERS = 'STORE_POST_HEADERS';

export const STORE_FETCHED_LOCATIONS = 'STORE_FETCHED_LOCATIONS';
export const LOCATIONS_LOADED = 'LOCATIONS_LOADED';
export const LOCATION_LOADED = 'LOCATION_LOADED';
export const STORE_LOCATION_HEADERS = 'STORE_LOCATION_HEADERS';

export const STORE_FETCHED_USER = 'STORE_FETCHED_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const INCREMENT_LOADING_PROGRESS = 'INCREMENT_LOADING_PROGRESS';
export const RESET_LOADING_PROGRESS = 'RESET_LOADING_PROGRESS';

export const STORE_FETCHED_SETTINGS = 'STORE_FETCHED_SETTINGS';
export const SETTINGS_LOADED = 'SETTINGS_LOADED';
