<template>
  <section class="band pt-16">
    <div class="band--inner px-16 pt-64 text-center">
      <h1>Whoa there!</h1>
      <h3>
        Looks like you've gotten off leash.
      </h3>
      <router-link :to="{ name: 'home' }" class="button relative inline-block mt-16">
        Let's get you back home.
        <span class="walking-dog">
          <font-awesome-icon size="lg" :icon="['fas', 'dog']" />
        </span>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FourOhFour',
};
</script>
