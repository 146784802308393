import './assets/tailwind.css';
import './assets/scss/app.scss';
import './assets/scss/wysiwyg.scss';
import 'alpinejs';

import * as GmapVue from 'gmap-vue';

// standard icons
import {
  faArrowLeft,
  faAsterisk,
  faCaretDown,
  faCheck,
  faChevronDown,
  faDog,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
// Social Icons
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';
import camelCase from 'lodash/camelCase';
import { library } from '@fortawesome/fontawesome-svg-core';
import upperFirst from 'lodash/upperFirst';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(VueMeta);

Vue.use(VueGtag, {
  config: { id: 'UA-4267465-14' },
}, router);

Vue.use(GmapVue, {
  load: {
    libraries: 'geometry',
    key: 'AIzaSyA1MU8w2AClZzR1Nz1IvBC0JKxPPhNVQS0',
    v: 'beta',
  },
  installComponents: true,
});

Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add(faFacebookF, faTwitter, faInstagram, faLinkedinIn);
library.add(
  faChevronDown,
  faCheck,
  faCaretDown,
  faArrowLeft,
  faTimes,
  faDog,
  faAsterisk,
  faSpinner,
);

const requireElements = require.context(
  // The relative path of the components folder
  './components/elements',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/,
);

requireElements.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireElements(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, ''),
    ),
  );

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig,
  );
});

const requireModules = require.context(
  // The relative path of the components folder
  './components/modules',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/,
);

requireModules.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireModules(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, ''),
    ),
  );

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig,
  );
});

Vue.config.productionTip = false;

// eslint-disable-next-line import/prefer-default-export
export const bus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
  async created() {
    this.$store.dispatch('getAllPages');
    this.$store.dispatch('getPosts', { sticky: true });
    await this.$store.dispatch('getAllSettings');
  },
}).$mount('#b4yp');
