<template>
  <section :id="id" class="posts band my-32" :class="[configuration]">
    <div class="band--inner px-4 py-8">
      <h3 class="heading-1 mb-12">{{ data.headline }}</h3>
      <div class="articles">
        <article
          class="flex flex-row items-center"
          v-for="(post, index) in posts"
          :key="index"
        >
          <div class="figure-wrapper">
            <figure class="overflow-hidden">
              <img :src="post.featuredImage.source_url" :alt="post.featuredImage.alt" />
            </figure>
            <router-link :to="post.slug" class="md:hidden button">Read More</router-link>
          </div>
          <div class="content">
            <h3 class="heading-3 mb-8">{{ post.title.rendered }}</h3>
            <ContentHolder :content="post.excerpt.rendered" />
            <router-link :to="post.slug" class="md:inherit button hidden">Read More</router-link>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PostsBand',
  props: {
    data: Object,
    per_page: Number,
    layout: String,
  },
  computed: {
    ...mapGetters({
      getPostsForPostBand: 'getPostsForPostBand',
      postsLoaded: 'postsLoaded',
    }),
    posts() {
      return this.getPostsForPostBand(this.numberOfPostsToDisplay);
    },
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return this.data.id || this._uid;
    },
  },
  data() {
    return {
      numberOfPostsToDisplay: this.data.per_page || 2,
      configuration: this.layout === 'row' ? 'posts-band--layout__row' : 'posts-band--layout__stacked',
    };
  },
  async mounted() {
    const {
      posts_to_display: display,
      exclude,
      posts,
    } = this.data;

    const args = {
      per_page: this.numberOfPostsToDisplay,
    };

    if (display === 'specific' && posts) {
      args.per_page = posts.length;
    }

    if (exclude) {
      args.exclude = exclude;
    }

    this.$store.dispatch('getPosts', args);
  },
};
</script>
