<template>
  <section :id="id" class="check-list band">
    <div class="band--inner md:px-16 px-4 py-8">
      <ul>
        <li v-for="(item, index) in data.items" :key="index" class="flex flex-row">
          <Checkmark v-if="data.id !== 'upgrade-winners'" />
          <div class="w-11/12">
            <ContentHolder :content="item.content" />
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Checkmark from '../../assets/svgs/check.svg';

export default {
  name: 'CheckedList',
  props: {
    data: Object,
  },
  components: {
    Checkmark,
  },
  computed: {
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return this.data.id || this._uid;
    },
  },
};
</script>
