<template>
  <section
    :id="id"
    class="band application-section"
    :class="{ 'has-error': hasError || hasFileTypeError }"
  >
    <div
      class="band--inner media-uploader--dropzone p-8"
      :class="{
        'media-uploader--uploading': isUploading,
        'media-uploader--uploaded': didUpload,
      }"
      @dragover="dragover"
      @dragleave="dragleave"
      @dragend="dragend"
      @drop="drop"
    >
      <FormHeadline :completed="isCompleted" :class="[widthClass]">
        <span class="flex-grow">{{label}}</span>
        <small>Allowed file types: <span>{{displayFilesTypes}}</span></small>
      </FormHeadline>
      <div class="media-uploader--input-error-container pl-12" :class="[widthClass]">
        <div
          class="media-uploader--visual-input flex flex-row"
          @click="openFileDialog($event)"
        >
          <input
            class="attached"
            type="text"
            :placeholder="placeholder"
            :value="uploadStatus"
            readonly
            aria-readonly
          />
          <small v-if="required">
            <font-awesome-icon class="mr-1" :icon="['fas', 'asterisk']" />
            Required
          </small>
          <button class="button secondary attached w-1/4">
            {{uploadButtonLabel}}
          </button>
        </div>
        <p class="error-message" v-if="hasFileTypeError">
          {{fileTypeErrorMessage}}
        </p>
        <p class="error-message" v-if="hasError">
          {{requiredErrorMessage}}
        </p>
      </div>
      <p class="small-teal ml-14"><slot></slot></p>
      <ul
        v-if="didUpload"
        class="media-uploader--file pl-12"
      >
        <li
          v-for="file of savedFiles"
          :key="file.id"
          class="flex flex-row items-center mt-8"
        >
          <figure
            class="media-uploader--file__image w-1/6"
          >
            <img
              v-if="file.media_type === 'image'"
              :src="file.source_url"
              :alt="file.alt_text"
            />
            <img
              v-if="file.media_type === 'file'"
              :src="fileImages[file.displayType]"
              role="presentation"
              alt=""
            />
            <button @click="removeFile($event, file)">
              <font-awesome-icon :icon="['fas', 'times']" />
            </button>
          </figure>
          <div class="w-5/6 pl-8">
            <h4 class="mb-0">{{file.title.rendered}}</h4>
            <p>Media type: <strong>{{file.media_type}}</strong></p>
          </div>
        </li>
      </ul>
      <div class="media-uploader--dropzone__highlight flex flex-row items-center">
        <h3>Drop here</h3>
      </div>
    </div>
  </section>
</template>

<script>
import api from '../../api';
import fileTypeGroups from '../../enums/file-type-groups';

const doc = require('../../assets/images/b4yp_DOC_upload.jpeg');
const docx = require('../../assets/images/b4yp_DOCX_upload.jpeg');
const pdf = require('../../assets/images/b4yp_PDF_upload.jpeg');

const mainDragEnd = (e) => {
  e.preventDefault();
  document.querySelectorAll('.media-uploader--dropzone').forEach((node) => node.classList.remove('media-uploader--dropzone__highlighted'));
};

export default {
  name: 'MediaUploader',
  props: {
    label: String,
    type: String,
    multiple: Boolean,
    required: Boolean,
    widthClass: String,
    requiredErrorMessage: {
      type: String,
      default: 'This field is required',
    },
  },
  mounted() {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = this.multiple;

    input.onchange = (e) => {
      this.handleFiles(e.target.files);
    };

    this.fileInput = input;

    const [firstMediaUploader] = this.$parent.$children.filter((component) => component.$options.name === 'MediaUploader');
    // eslint-disable-next-line no-underscore-dangle
    if (firstMediaUploader._uid === this._uid) {
      document.addEventListener('dragover', (e) => {
        e.preventDefault();
        document.querySelectorAll('.media-uploader--dropzone').forEach((node) => node.classList.add('media-uploader--dropzone__highlighted'));
      });
      document.addEventListener('drop', mainDragEnd);
      document.addEventListener('dragend', mainDragEnd);
    }
  },
  computed: {
    dropzone() {
      return this.$el?.querySelector('.media-uploader--dropzone');
    },
    uploadStatus() {
      const {
        isUploading,
        didUpload,
        uploadedFileCount,
        savedFiles: {
          length: savedFileCount,
        },
      } = this;
      let statusText = null;
      if (didUpload) {
        statusText = `Uploaded ${savedFileCount} file${savedFileCount > 1 ? 's' : ''}`;
      } else if (isUploading) {
        if (uploadedFileCount > 1) {
          statusText = `Uploading ${savedFileCount + 1} of ${uploadedFileCount} files...`;
        } else {
          statusText = 'Uploading 1 file...';
        }
      }
      return statusText;
    },
    uploadButtonLabel() {
      return this.multiple ? 'Select files' : 'Select a file';
    },
    placeholder() {
      return this.multiple ? 'Select files or drop some in' : 'Select a file or drop one in';
    },
    completed() {
      return this.isCompleted;
    },
    hasError() {
      return this.error;
    },
    hasFileTypeError() {
      return !!this.fileTypeErrorMessage;
    },
    displayFilesTypes() {
      return Object.keys(this.allowedFileTypes).join(', ');
    },
  },
  data() {
    const fileTypes = Object.keys(fileTypeGroups);
    if (!fileTypes.includes(this.type)) {
      throw new Error(`type must be one of "${fileTypes.join(',')}". "${this.type}" was passed.}`);
    }
    return {
      // eslint-disable-next-line no-underscore-dangle
      isCompleted: false,
      files: null,
      isUploading: false,
      didUpload: false,
      uploadedFiles: [],
      uploadedFileCount: 0,
      // eslint-disable-next-line no-underscore-dangle
      id: `media-uploader--${this._uid}`,
      uploadError: false,
      savedFiles: [],
      error: false,
      fileTypeErrorMessage: null,
      allowedFileTypes: fileTypeGroups[this.type],
      fileImages: {
        doc,
        docx,
        pdf,
      },
    };
  },
  methods: {
    async handleFiles(files) {
      this.error = false;
      this.fileTypeErrorMessage = null;
      const fs = this.multiple ? [...files] : [files[0]];
      const allowedFiles = fs
        .filter((file) => Object.values(this.allowedFileTypes).includes(file.type));
      if (allowedFiles.length !== files.length) {
        const allowedFilesTypesDisplay = Object.keys(this.allowedFileTypes);
        const someOrAll = allowedFiles.length ? 'Some' : 'All';
        const messageStart = this.multiple && allowedFiles.length ? `${someOrAll} of the uploaded file types are` : 'The uploaded file type is';
        this.fileTypeErrorMessage = `${messageStart} not allowed. Allowed types are ${allowedFilesTypesDisplay.join(', ')}.`;
        if (!allowedFiles.length) return;
      }
      this.uploadedFiles = this.multiple ? [...allowedFiles] : [allowedFiles[0]];
      this.uploadedFileCount = this.uploadedFiles.length;
      this.isUploading = true;
      await this.doUpload();
    },
    dragover(e) {
      e.preventDefault();

      this.dropzone.classList.add('media-uploader--active-dropzone');
    },
    dragleave(e) {
      e.preventDefault();

      this.dropzone.classList.remove('media-uploader--active-dropzone');
    },
    dragend(e) {
      e.preventDefault();

      mainDragEnd(e);
      this.dropzone.classList.remove('media-uploader--active-dropzone');
    },
    async drop(e) {
      e.preventDefault();
      e.stopPropagation();

      this.dropzone.classList.remove('media-uploader--active-dropzone');
      this.handleFiles(e.dataTransfer.files);
      mainDragEnd(e);
    },
    async doUpload() {
      await this.uploadedFiles.forEach(async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return api.sendFileToWP(formData, (response) => {
          if (response.isAxiosError) {
            this.uploadError = true;
          } else {
            response.data.displayType = Object
              .keys(this.allowedFileTypes)
              .find((key) => this.allowedFileTypes[key] === response.data.mime_type);
            this.savedFiles.push(response.data);
            if (this.savedFiles.length === this.uploadedFiles.length) {
              this.didUpload = true;
              this.isUploading = false;
              this.uploadedFileCount = 0;
              this.isCompleted = true;
              this.$emit('input', this.savedFiles);
            }
          }
        });
      });
    },
    openFileDialog(e) {
      e.preventDefault();
      this.fileInput.click();
    },
    removeFile(e, file) {
      e.preventDefault();
      api.removeFileFromWP(file.id, (response) => {
        // eslint-disable-next-line no-console
        if (!response.data.deleted) console.log(`Failed to delete file with id ${file.id}`);
      });
      this.savedFiles.splice(this.savedFiles.indexOf(file), 1);
      this.$emit('input', this.savedFiles);
      if (this.savedFiles.length < 1) {
        this.didUpload = false;
        this.isCompleted = false;
      }
    },
    validateInput() {
      if (this.required && this.savedFiles.length === 0) {
        this.error = true;
      } else {
        this.isCompleted = true;
      }
    },
  },
};
</script>
