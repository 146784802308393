/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import api from '../../api';
import * as types from '../mutation-types';
import { processPosts } from './post';
import LocationMarkerBaseIcon from '../../utils/location-icon-data';

// initial state
const state = {
  all: {},
  loaded: false,
  locationLoaded: false,
  metadata: {
    currentPage: 1,
    hasPages: false,
    fetchedLastPage: false,
    totalItems: 0,
  },
};

// getters
const getters = {
  getLocations: (state) => (state.loaded ? Object.values(state.all) : []),
  getLocationsSubset: (state) => (offset, limit) => Object
    .values(state.all)
    .slice(offset, limit + offset),
  getLocationNameMatch: (state) => (query, locations = []) => {
    const locationsToSearch = locations.length > 0 ? locations : Object.values(state.all);
    return locationsToSearch.filter(
      (item) => item.title.rendered.toLowerCase().match(query.toLowerCase()),
    );
  },
  getLocationStateMatch: (state) => (requestedState) => Object
    .values(state.all)
    .filter((location) => location.acf.location_details.park_location.state === requestedState),
  locationsLoaded: (state) => state.loaded,
  location: (state) => (name) => {
    if (typeof name === 'undefined') return null;
    if (typeof name !== 'string') throw new Error('passed name must be a string');

    const location = state.all[name];
    return location;
  },
  locationLoaded: (state) => state.locationLoaded,
  locationsMetadata: (state) => state.metadata,
  getStates: (state) => [
    ...new Set(
      Object
        .values(state.all)
        .map((location) => location.acf.location_details.park_location.state),
    ),
  ],
  getFinalistLocations: (state) => {
    const locations = state.loaded ? Object.values(state.all) : [];
    return locations.filter(({ finalist }) => finalist);
  },
};

// actions
const actions = {
  getLocations({ commit, dispatch, state }, args) {
    commit(types.LOCATIONS_LOADED, false);
    api.getLocations(args, (posts, headers) => {
      commit(types.STORE_FETCHED_LOCATIONS, { posts: processPosts(posts, '/locations/') });
      const totalPages = headers && headers['x-wp-totalpages'] ? parseInt(headers['x-wp-totalpages'], 10) : 0;
      if (totalPages > 1 && args.page < totalPages) {
        const page = state.metadata.currentPage + 1;
        dispatch('getLocations', { ...args, page });
      }
      commit(types.STORE_LOCATION_HEADERS, { headers, page: args.page });
      commit(types.LOCATIONS_LOADED, true);
    });
  },
  getLocation({ commit, state }, postName) {
    commit(types.LOCATION_LOADED, false);
    const post = state.all[postName];
    if (post) {
      commit(types.LOCATION_LOADED, true);
    } else {
      api.getLocations({ slug: postName }, (posts) => {
        commit(types.STORE_FETCHED_LOCATIONS, { posts: processPosts(posts, '/locations/') });
        commit(types.LOCATION_LOADED, true);
      });
    }
  },
};

// mutations
const mutations = {
  [types.STORE_FETCHED_LOCATIONS](state, { posts }) {
    posts.map((post) => {
      const name = post.postName;
      // const currentYear = new Date().getFullYear();
      const {
        park_location: {
          lat,
          lng,
          address,
        },
        year_awarded: yearAwarded,
        enable_details_page: enableDetailsPage,
        park_type: parkType,
      } = post.acf.location_details;
      post.position = { lat, lng };
      post.committed_at = Date.now();
      post.icon = LocationMarkerBaseIcon(/* parseInt(yearAwarded, 10) === currentYear */);
      post.address = address?.replace(', USA', '');
      post.enable_details_page = enableDetailsPage;
      post.windowOpen = false;
      post.finalist = false; // parseInt(yearAwarded, 10) === currentYear;
      post.year_awarded = yearAwarded;
      post.park_type = parkType;

      state.all[name] = post;

      return post;
    });
  },

  [types.STORE_LOCATION_HEADERS](state, { headers, page = 1 }) {
    const totalPages = headers && headers['x-wp-totalpages'] ? parseInt(headers['x-wp-totalpages'], 10) : 0;
    const totalItems = headers && headers['x-wp-total'] ? parseInt(headers['x-wp-total'], 10) : 0;
    const fetchedItemCount = Object.values(state.all).length;
    state.metadata = {
      hasPages: totalPages > 1,
      fetchedLastPage: page === totalPages,
      fetchedAllItems: totalItems === fetchedItemCount,
      totalItems,
      totalPages,
    };
    if (page <= totalPages) {
      state.metadata.currentPage = page;
    } else {
      state.metadata.currentPage = 1;
    }
  },

  [types.LOCATIONS_LOADED](state, val) {
    state.loaded = val;
  },

  [types.LOCATION_LOADED](state, val) {
    state.locationLoaded = val;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
