/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import api from '../../api';
import * as types from '../mutation-types';

// initial state
const state = {
  all: [],
  loaded: false,
  page: null,
};

// getters
const getters = {
  allPages: (state) => state.all,
  allPagesLoaded: (state) => state.loaded,
  page: (state) => (id) => {
    const field = typeof id === 'number' ? 'id' : 'slug';
    const page = state.all.filter((page) => page[field] === id);
    return page[0] ? page[0] : false;
  },
  pageContent: (state) => (id) => {
    const field = typeof id === 'number' ? 'id' : 'slug';
    const page = state.all.filter((page) => page[field] === id);

    return page[0] ? page[0].content.rendered : false;
  },
  somePages: (state) => (limit) => {
    if (state.all.length < 1) {
      return false;
    }
    const all = [...state.all];
    return all.splice(0, Math.min(limit, state.all.length));
  },
};

// actions
const actions = {
  getAllPages({ commit }) {
    api.getPages((pages) => {
      commit(types.STORE_FETCHED_PAGES, { pages });
      commit(types.PAGES_LOADED, true);
    });
  },
};

// mutations
const mutations = {
  [types.STORE_FETCHED_PAGES](state, { pages }) {
    state.all = pages;
  },

  [types.PAGES_LOADED](state, val) {
    state.loaded = val;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
