<template>
  <section class="gallery-band band">
    <div class="band--inner md:flex-row md:px-16 flex flex-col items-start px-4">
      <div class="md:w-1/2 md:ml-8 md:order-2 md:mb-0 w-full mb-16">
        <figure>
          <img class="mx-auto" :src="currentImage.url" :alt="currentImage.alt" />
        </figure>
      </div>
      <ul class="md:w-1/2 md:mr-8 md:order-1 w-full">
        <li v-for="(image, index) in data.gallery" :key="index">
          <button @click="changeImage(image)" :class="{
            'is-active': image === currentImage
          }">
            <figure>
              <img :src="image.url" :alt="image.alt" />
            </figure>
          </button>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'GalleryBand',
  props: {
    data: Object,
  },
  data() {
    return {
      currentImage: this.data.gallery[0],
    };
  },
  methods: {
    changeImage(image) {
      this.currentImage = image;
    },
  },
};
</script>
