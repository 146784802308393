<template>
  <div class="locations-map-wrapper">
    <section class="locations-map">
      <GmapMap
        ref="map"
        :center="center"
        :zoom="4.75"
        :options="options"
        @click="whichInfoWindowOpen = false"
      >
        <template v-if="showMarkers">
          <GmapCluster
            :averageCenter="true"
            :styles="clusterStyles"
            :calculator="clusterCalculator"
            ref="clusterer"
            @click="clickCluster($event, $refs.map.$mapObject)"
          >
            <GmapMarker
              v-for="location in locationsForMap"
              :position="location.position"
              :icon="location.icon"
              :key="location.id"
              @click="clickMarker(location, $refs.map.$mapObject)"
            >
              <GmapInfoWindow
                @closeclick="whichInfoWindowOpen = null"
                :opened="openInfoWidow === location.id"
                :position="location.position"
              >
                <div class="info-window md:flex-row flex flex-col">
                  <figure
                    class="md:w-2/5 md:h-auto w-full h-32"
                    :class="{ 'finalist': location.finalist }"
                    v-if="location.featuredImage.source_url"
                  >
                    <img
                      :src="location.featuredImage.source_url"
                      :alt="location.featuredImage.alt"
                    />
                    <div
                      v-if="location.finalist"
                      class="tag tag--finalist flex flex-row items-center"
                    >
                      <Award
                        class="inline-block float-left w-4 ml-auto mr-2"
                      />
                      <div class="mr-auto">{{location.year_awarded}} Finalist</div>
                    </div>
                  </figure>
                  <div class="content md:w-2/3 md:text-left w-full text-center">
                    <h3 class="mt-2 standout--dark-teal">{{ location.title.rendered }}</h3>
                    <p>{{ location.address }}</p>
                    <button
                      v-if="location.enable_details_page && location.finalist && isVotingEnabled"
                      :to="location.slug"
                      class="button button--compact secondary inline-block mb-2"
                      @click="doOpenVotingModal(location)"
                    >
                      Vote!
                    </button>
                    <router-link
                      v-if="location.enable_details_page"
                      :to="location.slug"
                      class="button button--compact inline-block mb-2 ml-2"
                    >
                      Learn More
                    </router-link>
                  </div>
                </div>
              </GmapInfoWindow>
            </GmapMarker>
          </GmapCluster>
        </template>
      </GmapMap>
    </section>
    <section class="band form">
      <div class="band--inner">
        <div class="angled-box md:w-4/5 md:py-8 w-full px-8 py-4 m-auto -mt-5">
          <div class="map-legend" v-if="hasFinalistLocations">
            <LocationMarkerRed />
            <p>{{ currentYear }} PetSafe® Unleashed™ Finalist</p>
          </div>
          <form class="md:pr-16">
            <div class="md:flex-row flex flex-col items-center">
              <p
                class="md:w-1/6 md:text-right md:mb-0 md:pr-4 w-full mb-4 text-center"
              >Filter by: </p>
              <div class="md:w-5/6 flex flex-row items-center w-full">
                <div
                  class="input-wrapper w-2/5"
                  :class="{ 'has-input': state }"
                >
                  <select id="state" v-model="state">
                    <option></option>
                    <option v-for="(state, index) in states" :key="index" :value="state">
                      {{ state }}
                    </option>
                  </select>
                  <label for="state">State</label>
                </div>
                <p class="mx-auto"> or </p>
                <div
                  class="input-wrapper w-2/5"
                  :class="{ 'has-input': zip !== '' && zip !== null }"
                >
                  <input id="zip" type="text" v-model="zip">
                  <label for="zip">Zip</label>
                </div>
              </div>
            </div>
            <div class="md:flex-row flex flex-col items-center mt-8">
              <p class="md:w-1/6 md:text-right md:mb-0 md:pr-4 w-full mb-4 text-center">Search: </p>
              <div class="input-wrapper md:w-5/6 w-full" :class="{ 'has-input': query !== '' }">
                <input id="search" type="text" v-model="query">
                <label for="search">Park Name</label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section v-if="locationsLoaded" class="band locations-list" id="locations-list">
      <div class="band--inner">
        <div v-if="errors.length" class="errors">
          <p v-for="(error, index) in errors" :key="index">{{error}}</p>
        </div>
        <ul class="mt-16 list-none">
          <li
            class="md:flex-row flex flex-col items-center p-4"
            v-for="location in locationsForDisplay"
            :key="location.id"
          >
            <div class="md:w-4/5 md:mb-0 flex flex-row items-center w-full mb-6">
              <figure v-if="location.featuredImage" class="md:w-1/5 w-2/5">
                <img :src="location.featuredImage.source_url" :alt="location.featuredImage.alt" />
                <div
                  v-if="location.finalist"
                  class="tag tag--finalist flex flex-row items-center"
                >
                  <Award
                    class="inline-block float-left w-4 ml-auto mr-2"
                  />
                  <div class="mr-auto">{{location.year_awarded}} Finalist</div>
                </div>
              </figure>
              <div class="details md:w-4/5 w-3/5 ml-6">
                <h3 v-html="location.title.rendered"></h3>
                <p>{{ location.address }}</p>
              </div>
            </div>
            <div class="actions md:w-auto md:ml-auto w-full">
              <button
                v-if="location.enable_details_page && location.finalist && isVotingEnabled"
                :to="location.slug"
                class="button secondary block w-full mb-3 ml-auto"
                @click="doOpenVotingModal(location)"
              >
                Vote!
              </button>
              <router-link
                v-if="location.enable_details_page"
                :to="location.slug"
                class="button block w-full ml-auto"
              >
                Learn More
              </router-link>
            </div>
          </li>
        </ul>
        <button
          v-if="showShowMoreButton"
          class="button text-only md:ml-auto md:mr-0 block mx-auto mt-16"
          v-on:click="populateLocations(10)"
          :disabled="showingAllLocationsInList"
        >
          {{showMoreLabel}}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable no-param-reassign */
import { mapGetters } from 'vuex';
import { gmapApi } from 'gmap-vue';
import GmapCluster from 'gmap-vue/dist/components/cluster';
import { debounce } from 'lodash';
import MapOptions, { zoomedStyles } from '../utils/map-options';
import LocationMarkerRed from '../assets/svgs/location-marker-red.svg';
import Award from '../assets/svgs/award.svg';

export default {
  name: 'LocationsMap',
  components: {
    LocationMarkerRed,
    GmapCluster,
    Award,
  },
  props: {
    data: Object,
  },
  computed: {
    ...mapGetters({
      allLocations: 'getLocations',
      locationsLoaded: 'locationsLoaded',
      getLocationsForDisplay: 'getLocationsSubset',
      getLocationNameMatch: 'getLocationNameMatch',
      getLocationStateMatch: 'getLocationStateMatch',
      locationsMetadata: 'locationsMetadata',
      states: 'getStates',
    }),
    google: gmapApi,
    /**
     * showingAllLocationsInList
     * returns true if all locations are showing in the list, false otherwise
     */
    showingAllLocationsInList() {
      const { totalItems } = this.locationsMetadata;
      return this.locations.length === totalItems;
    },
    /**
     * showMoreLabel
     * returns the label for the link below the list determined off of `showingAllLocationsInList`
     */
    showMoreLabel() {
      return this.showingAllLocationsInList ? 'Showing All!' : 'Show More...';
    },
    /**
     * locationsForDisplay
     * returns the locations to populate in the list
     */
    locationsForDisplay() {
      return this.matchedLocations.length ? this.matchedLocations : this.locations;
    },
    /**
     * locationsForMap
     * returns the locations to populate in the map
     */
    locationsForMap() {
      return this.matchedLocations.length ? this.matchedLocations : this.allLocations;
    },
    /**
     * hasFinalistLocations
     * returns a boolean indicating if there are finalist locations
     */
    hasFinalistLocations() {
      return this.allLocations.filter((location) => location.finalist).length;
    },
    /**
     * errors
     * returns [] containing the errors for the seasrch and/or filtering
     */
    errors() {
      return [this.searchError, this.filterError].filter((error) => !!error);
    },
    /**
     * showShowMoreButton
     * returns false if we should display the button below the list
     *    (when there are matchedLocations from the search or filter), true otherwise
     */
    showShowMoreButton() {
      return this.matchedLocations.length < 1;
    },
    /**
     * showMarkers
     * returns true if we've retrieved our locations from the server
     *    and plotted the bounds of the map
     */
    showMarkers() {
      return this.locationsLoaded && this.mapDrawn;
    },
    openInfoWidow() {
      return this.whichInfoWindowOpen;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    /**
     *  Will become a setting in the backend
     */
    isVotingEnabled() {
      return false;
    },
  },
  async mounted() {
    this.$refs.map.$mapPromise.then(async () => {
      await this.$store.dispatch('getLocations', { per_page: 100, page: 1 });
      this.adjustMap(this.startingBounds, this.initialLoad);
      const map = this.$refs.map.$mapObject;
      this.google.maps.event.addListener(map, 'zoom_changed', () => {
        const zoom = map.getZoom();
        if (zoom >= 8) {
          map.setOptions(zoomedStyles);
        } else {
          map.setOptions(MapOptions);
        }
      });
    });
  },
  watch: {
    /**
     * When the locations have loaded, grap N to display in the list below map
     */
    locationsLoaded(loaded) {
      if (loaded) {
        this.populateLocations(10);
      }
    },
    /**
     * Fire search 1 second after `query` stops changing
     */
    query: debounce(function debouncedSearchParkName() {
      this.performSearch();
    }, 1000),
    /**
     * Fire zipcode filter 1 second after `zip` stops changing
     */
    zip: debounce(function debouncedFilterByZip(zip) {
      if (zip === null) return;
      this.state = null;
      this.performSearch();
    }, 1000),
    /**
     * Filter by state when `state` changes
     */
    state(state) {
      if (state === null) return;
      this.zip = null;
      this.performSearch();
    },
  },
  data() {
    return {
      initialLoad: true,
      zipcodeRadius: 100,
      filtered: false,
      perPage: 1,
      locations: [],
      matchedLocations: [],
      currentPage: 1,
      state: '',
      zip: '',
      query: '',
      searchError: null,
      filterError: null,
      center: { lat: 43.5, lng: -96.35 },
      options: MapOptions,
      mapDrawn: true,
      whichInfoWindowOpen: null,
      /**
       * Initial bounds of the map is the continental US
       */
      startingBounds: [
        {
          position: { lat: 40.351289, lng: -124.244385 },
        },
        {
          position: { lat: 24.891752, lng: -98.4375 },
        },
        {
          position: { lat: 44.488196, lng: -70.290656 },
        },
        {
          position: { lat: 49.000282, lng: -101.37085 },
        },
      ],
      clusterStyles: [
        {
          textColor: '#263746',
          height: 36,
          width: 36,
          url: require('../assets/images/map-cluster.png'),
          anchorIcon: [18, 18],
          anchorText: [6, 28],
          textSize: 18,
          textWeight: 'bold',
          fontWeight: 900,
          fontFamily: "'adelle-sans', sans-serif",
        },
        {
          textColor: '#263746',
          height: 36,
          width: 36,
          url: require('../assets/images/map-cluster-red.png'),
          anchorIcon: [18, 18],
          anchorText: [6, 28],
          textSize: 18,
          textWeight: 'bold',
          fontWeight: 900,
          fontFamily: "'adelle-sans', sans-serif",
        },
      ],
    };
  },
  methods: {
    populateLocations(perPage = 10) {
      const offset = this.locations.length;
      const newLocations = this.getLocationsForDisplay(offset, perPage);
      this.locations = this.locations.concat(newLocations);
    },
    async performSearch() {
      this.clearErrors();
      // this.matchedLocations = [];
      const {
        query,
        zip,
        state,
      } = this;
      try {
        if (state && state !== '') {
          this.filterByState(state);
        }
        if (zip && zip !== '') {
          await this.filterByZip(zip);
        }
        if (query) {
          this.searchParkName(query);
        }
        this.setMatchedLocations(this.matchedLocations);
      } catch (e) {
        if (e.code === 'ZERO_RESULTS') this.filterError = 'Invalid zip provided.';
        // eslint-disable-next-line no-console
        else console.error(e);
      }
    },
    /**
     * Find locations with names including `query` in the locations displayed on the map
     */
    searchParkName(query = null) {
      const matchedLocations = this.getLocationNameMatch(
        query, this.matchedLocations.length > 0 ? this.matchedLocations : [],
      );
      if (matchedLocations.length < 1) {
        this.searchError = `No matches found for "${query}."`;
        if (this.zip !== '' && this.zip !== null) this.searchError += ` Showing other dog parks in ${this.zip}.`;
        if (this.state !== '' && this.state !== null) this.searchError += ` Showing other dog parks in ${this.state}.`;
      } else {
        this.matchedLocations = matchedLocations;
      }
    },
    /**
     * Find locations with requested state
     */
    filterByState(state) {
      this.zip = null;
      this.matchedLocations = this.getLocationStateMatch(state);
    },
    /**
     * Find locations within 100 miles of provided zipcode
     */
    async filterByZip(zip) {
      const geocoder = new this.google.maps.Geocoder();
      const { results } = await geocoder.geocode({
        componentRestrictions: { postalCode: zip },
      });
      if (!results.length) return;
      const matchedLocations = await this.geocodeCallback(results);
      if (matchedLocations.length) {
        this.matchedLocations = matchedLocations;
      } else {
        this.filterError = `Sorry, no dog parks found within ${this.zipcodeRadius} miles of zipcode ${zip}. Please try another search.`;
      }
    },
    setMatchedLocations(matchedLocations) {
      if (matchedLocations.length) {
        this.adjustMap(matchedLocations);
      } else {
        this.adjustMap(this.startingBounds, true);
        this.state = '';
        this.zip = '';
        this.query = '';
      }
    },
    /**
     * Adjust bounds of map when the locations update
     */
    adjustMap(locations, initialSet) {
      this.mapDrawn = false;
      this.$refs.map.$mapPromise.then((map) => {
        const bounds = new this.google.maps.LatLngBounds();
        locations.forEach((location) => {
          const {
            position: { lat, lng },
          } = location;
          bounds.extend(new this.google.maps.LatLng(lat, lng));
        });
        map.setCenter(bounds.getCenter());
        let mapPadding = null;
        if (window.innerWidth >= 768) {
          mapPadding = {
            top: 200,
            bottom: 50,
          };
        }
        map.fitBounds(bounds, mapPadding);
        if (initialSet) {
          this.initialLoad = false;
          const listener = this.google.maps.event.addListener(map, 'idle', async () => {
            map.setZoom(map.getZoom() + 0.75);
            this.google.maps.event.removeListener(listener);
            this.mapDrawn = true;
          });
        } else {
          this.mapDrawn = true;
        }
      });
    },
    async geocodeCallback(res) {
      const { location: zipcodeLocation } = res[0].geometry;
      const matchedLocations = this.allLocations.filter((location) => {
        const {
          position: { lat, lng },
        } = location;
        const position = new this.google.maps.LatLng(lat, lng);
        const distance = this.google.maps.geometry.spherical
          .computeDistanceBetween(zipcodeLocation, position);
        const miles = Math.ceil(distance * 0.000621371);
        return miles < this.zipcodeRadius;
      }, this);
      return matchedLocations;
    },
    clearErrors() {
      this.filterError = null;
      this.searchError = null;
    },
    clickCluster(e, map) {
      const markers = e.getMarkers();
      const locations = markers.map((marker) => {
        marker.setMap(map);
        return {
          position: { lat: marker.position.lat(), lng: marker.position.lng() },
        };
      });
      const bounds = new this.google.maps.LatLngBounds();
      locations.forEach((location) => {
        const {
          position: { lat, lng },
        } = location;
        bounds.extend(new this.google.maps.LatLng(lat, lng));
      });
      map.setCenter(bounds.getCenter());
      let mapPadding = null;
      if (window.innerWidth >= 768) {
        mapPadding = {
          top: 250,
          bottom: 100,
        };
      }
      map.fitBounds(bounds, mapPadding);
    },
    clickMarker(location, map) {
      map.panTo(location.position);
      this.whichInfoWindowOpen = location.id;
    },
    clusterCalculator(clusterMarkers) {
      const count = clusterMarkers.length;
      const index = clusterMarkers.some(({ icon }) => icon.fillColor === '#fc3319')
        ? 2
        : 1;

      return {
        text: count.toString(),
        index,
      };
    },
    doOpenVotingModal(location) {
      this.$emit('doOpenVotingModal', {
        id: location.id,
        title: location.title.rendered,
        address: location.address,
        featuredImage: location.featuredImage,
      });
    },
  },
};
</script>
