import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Page from '../views/Page.vue';
import Post from '../views/Post.vue';
import Location from '../views/Location.vue';
import FourOhFour from '../views/FourOhFour.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/post/:postSlug',
    name: 'Post',
    component: Post,
  },
  {
    path: '/locations/:postSlug',
    name: 'Location',
    component: Location,
  },
  {
    path: '/:pageSlug',
    name: 'Page',
    component: Page,
  },
  {
    path: '*',
    name: 'FourOhFour',
    component: FourOhFour,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
});

export default router;
