<template>
  <div class="input-error-container" :class="{ 'has-error': requiredError }">
    <div class="input-wrapper select" :class="{ 'has-input': value !== '' }">
      <select ref="select" :id="id" @change="updateValue($event.target.value)">
        <option></option>
        <option
          v-for="(option, index) in displayOptions"
          :key="index"
          :value="option.value"
          :selected="value === option.value"
        >
          {{option.label}}
        </option>
      </select>
      <label :for="id">{{label}}</label>
    </div>
    <p v-if="requiredError" class="error-message">{{requiredErrorMessage}}</p>
    <LabeledTextarea
      class="mt-6 ml-6"
      ref="otherTextarea"
      v-if="showOtherTextarea"
      :label="otherOptionLabel"
      :validate="validateInput"
      :required="true"
      v-model="explanation"
    />
  </div>
</template>

<script>
import { otherOption } from '../../enums/application-dropdown-options';

export default {
  name: 'LabeledSelect',
  props: {
    label: String,
    id: String,
    value: String,
    options: Array,
    provideOtherOption: Boolean,
    otherOptionLabel: {
      type: String,
      default: 'Other...',
    },
    otherExplanation: String,
    passAlongOtherExplanation: Function,
    errorMessage: String,
    required: Boolean,
    requiredErrorMessage: {
      type: String,
      default: 'This field is required',
    },
  },
  mounted() {
    if (this.$parent.isComplete) this.$parent.isComplete();
  },
  destroyed() {
    this.$emit('input', '');
  },
  computed: {
    displayOptions() {
      return this.provideOtherOption ? [...this.options, ...this.otherOption] : this.options;
    },
    showOtherTextarea() {
      return this.value === this.otherOption[0].value;
    },
  },
  watch: {
    explanation(explanation) {
      this.passAlongOtherExplanation(explanation);
    },
  },
  data() {
    return {
      otherOption,
      requiredError: false,
      isValid: !this.required,
      explanation: this.otherExplanation,
    };
  },
  methods: {
    updateValue(value) {
      this.requiredError = false;
      this.$emit('input', value);
      this.validateInput(value);
    },
    validateInput(value) {
      let isValid = true;
      if (this.required && value === this.otherOption[0].value && this.otherExplanation === '') {
        this.isValid = false;
        isValid = false;
      } else if (this.required && value === '') {
        this.requiredError = true;
        this.isValid = false;
        isValid = false;
      } else {
        this.isValid = true;
      }

      if (this.$parent.isComplete) this.$parent.isComplete();
      return isValid;
    },
  },
};
</script>
